<template>
  <div class="home">
    <div class="flex-row">
      <div class="home-left">
        <el-row :gutter="16">
          <el-col
            :span="8"
            v-for=" (item,index) in buttons"
            :key="index"
          >
            <TabItem :template="item" />
          </el-col>
        </el-row>
        <!-- AI工坊 -->
        <div
          class="task-bg"
          style="height: 202px;"
        >
          <div
            class="flex-row-center f-between"
            style="min-height: 54px;"
          >
            <div class="flex-row-center">
              <div class="blue-line"></div>
              <span class="home-title mr20">{{ $t('workbench.aiWorkshop') }}</span>
            </div>
          </div>
          <div class="workshop-box">
            <div
              v-for="(item,index) in aiWorkshopList"
              :key="index"
              :class="['workshop-item', `workshop-item${index+1}`]"
              @click="goTarget(item.path)"
            >
              <div class="workshop-icon-box">
                <div :class="['workshop-icon', `workshop-icon${index+1}`]">
                  <i :class="['iconfont',  item.icon]"></i>
                </div>
                <div class="open-text">
                  {{ $t('workbench.workshopEnter') }} <i class="iconfont icon-xiangyoujiantou"></i>
                </div>
              </div>
              <div class="workshop-title">{{ item.title }}</div>
              <div class="workshop-content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-right">
        <!-- 账号统计 -->
        <div
          v-if="false"
          class="matrix-container mb10"
          v-loading="workLoading"
        >
          <div class="flex-row-center">
            <i
              class="iconfont icon-zhanghaoguanlibeifen mr5"
              style="color: #0156FF;font-size: 36px;"
            ></i>
            <div class="f-grow ml10">
              <div class="work-title mb5">{{ $t('workbench.accountCount') }}：{{ workStatistics.socialCount || 0 }}</div>
              <router-link
                class="link-type"
                :to="'/account'"
              >{{ $t('workbench.newAccount') }}</router-link>
            </div>
            <!-- <router-link class="link-type" :to="'/account'">查看数据总览</router-link> -->
          </div>
          <el-row
            :gutter="1"
            style="margin-top: 69px;"
          >
            <el-col :span="6">
              <div
                class="flex-column-center"
                style="border-right: 1px solid #F6F6F6;"
              >
                <span class="fans-title">{{ $t('workbench.fanCount') }}</span>
                <span class="fans-num">{{ workStatistics.fansCount || 0 }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                class="flex-column-center"
                style="border-right: 1px solid #F6F6F6;"
              >
                <span class="fans-title">{{ $t('workbench.workCount') }}</span>
                <span class="fans-num">{{ workStatistics.videoCount || 0 }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div
                class="flex-column-center"
                style="border-right: 1px solid #F6F6F6;"
              >
                <span class="fans-title">{{ $t('workbench.playCount') }}</span>
                <span class="fans-num">{{ workStatistics.viewCount || 0 }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="flex-column-center">
                <span class="fans-title">{{ $t('workbench.commentCount') }}</span>
                <span class="fans-num">{{ workStatistics.commentCount || 0 }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="task-bg-border">
          <div
            class="flex-row-center ml16"
            style="min-height: 54px;"
          >
            <div class="blue-line"></div>
            <span class="home-title mr20">{{ $t('workbench.communityList') }}</span>
          </div>
          <div class="dynamic-scroll-container">
            <vueScrolling :data="communityList" />
          </div>
        </div>
      </div>
    </div>
    <!-- 视频创作 -->
    <div
      class="task-bg mt16"
      style="height: 212px;"
    >
      <div
        class="flex-row-center f-between"
        style="min-height: 54px;"
      >
        <div class="flex-row-center">
          <div class="blue-line"></div>
          <span class="home-title mr20">{{ $t('workbench.videoCreation') }}</span>
        </div>
        <div class="home-title">
          <span class="ml5">{{ $t('workbench.videoMix') }}：{{ videoStatistics.clip }}</span>
          <span class="ml5">{{ $t('workbench.videoByText') }}：{{ videoStatistics.aiClip }}</span>
          <span class="ml5">{{ $t('workbench.AIVideo') }}：{{ videoStatistics.ai }}</span>
          <el-tooltip
            class="ml10"
            effect="dark"
            :content="$t('refresh')"
            placement="top"
          >
            <el-button
              size="mini"
              circle
              icon="el-icon-refresh"
              @click="getVideoList"
            />
          </el-tooltip>
        </div>
      </div>
      <el-empty
        v-if="videos.length === 0"
        v-loading="loadingVideo"
        :image-size="40"
      ></el-empty>
      <el-row
        v-else
        v-loading="loadingVideo"
        :gutter="16"
      >
        <el-col
          :span="6"
          v-for=" item in videos"
          :key="item.id"
        >
          <div
            class="video-container"
            @click="goVideoManager(item)"
          >
            <video
              :src="item.url"
              alt="video"
              class="video-cover f-shrink"
            />
            <div class="video-right">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.taskName"
                placement="top"
              >
                <div class="video-title single-line-ellipsis">{{ item.taskName }}</div>
              </el-tooltip>
              <div class="video-content">{{ $t('videoManager.length') }}：{{ item.videoTotalLength }}</div>
              <div
                class="flex-row-center f-between"
                style="margin-top: 22px;"
              >
                <dict-tag
                  class="time"
                  :options="dict.type.issue_type"
                  :value="item.taskType"
                />
                <div class="time">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 发布任务 -->
    <div
      class="task-bg mt16"
      style="height: 204px;"
    >
      <div
        class="flex-row-center"
        style="min-height: 54px;"
      >
        <div class="blue-line"></div>
        <span class="home-title mr20">{{ $t('workbench.publishTask') }}</span>
        <!-- <el-radio-group
            v-model="socialType"
            class="custom-radio-group"
            @change="getPublishList"
          >
            <el-radio
              v-for="dict in dict.type.social_platform"
              :key="dict.value"
              :label="dict.value"
              class="radio-social"
            >
              <template v-slot:default>
                <i
                  class="iconfont"
                  :class="['icon-' + dict.value + '-normal', socialType==dict.value ? dict.value : 'unbind']"
                  style="margin-right: 2px;"
                ></i>
                {{ dict.label }}
              </template>
            </el-radio>
          </el-radio-group> -->
      </div>
      <el-empty
        v-if="publish.length === 0"
        v-loading="loading"
        :image-size="40"
      ></el-empty>
      <el-row
        v-else
        v-loading="loading"
        :gutter="16"
      >
        <el-col
          :span="6"
          v-for=" item in publish"
          :key="item.id"
        >
          <div
            class="publish-containerContent"
            @click="goTarget('/issueManager')"
          >
            <div class="flex-row">
              <div class="point f-shrink"></div>
              <el-tooltip
                effect="dark"
                :content="item.postContent"
                placement="top"
              >
                <span class="post-content line-2 f-grow">{{ item.postContent || "--" }}</span>
              </el-tooltip>
            </div>
            <div class="flex-row-center f-end publish-status">
              <span v-if="item.status == 0">{{$t('issueManager.statusDraft')}}</span>
              <span
                v-if="item.status == 1"
                style="color: #ED7B2F;"
              ><i class="iconfont icon-dacha1"></i>{{ $t('issueManager.publishing') }}</span>
              <span
                v-if="item.status == 2"
                style="color: #00A870;"
              > <i class="iconfont icon-a-dacha3"></i>{{ $t('issueManager.published') }}</span>
              <span
                v-if="item.status == 3"
                style="color: #E34D59;"
              ><i class="iconfont icon-a-dacha2"></i>{{ $t('issueManager.failed') }}</span>
              <!-- <dict-tag
                    :options="dict.type.publish_time_type"
                    :value="item.publishTimeType"
                  /> -->
            </div>
            <div class="flex-row-center f-between time">
              <div class="icon-gray-bg flex-center">
                <i
                  v-if="item.socialType"
                  class="iconfont"
                  :class="['icon-' + item.socialType + '-normal', item.socialType]"
                ></i>
              </div>
              <div>{{ item.publishTime }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 公告、帮助文档 -->
    <div
      v-if="noticeList && noticeList.length > 0"
      class="notice-bg-border"
    >
      <!-- <i class="iconfont icon-shengyin color-blue mr8"></i>
      <span class="f-shrink home-title">
        {{ $t('workbench.announcement') }} |
      </span> -->
      <!-- <div class="carousel-container"> -->
      <!-- <el-carousel
          height="30px"
          direction="vertical"
          width="100%"
        >
          <el-carousel-item
            v-for="item in noticeList"
            v-bind:key="item.url"
            class="notice-title"
          >
            <span @click="showNotice(item)">{{ item.noticeTitle }}</span>
          </el-carousel-item>
        </el-carousel> -->
      <!-- </div> -->
      <div
        v-for="(item, index) in noticeList"
        v-bind:key="item.url"
        class="notice flex-row-center"
      >
        <i
          v-if="index < 3"
          class="iconfont icon-redu"
        ></i>
        <div
          v-else
          class="point-blue f-shrink"
        ></div>
        <span
          class="notice-title"
          @click="showNotice(item)"
        >{{ item.noticeTitle }}</span>
        <div
          v-if="index === 0"
          class="notice-hot"
        >{{ $t("workbench.hot") }}</div>
        <div
          v-else-if="index === 1 || index === 2"
          class="notice-new"
        >{{ $t("workbench.new") }}</div>
      </div>
    </div>
    <CompanyInfo
      ref="companyInfo"
      :visible.sync="infoVisible"
      :user="user"
    />
  </div>
</template>

<script>
import TabItem from "./home/TabItem.vue";
import { getQuota, myMerchant } from "../api/system/merchant";
import { getAccount, userStatistics, listAccount } from "../api/account/tiktok";
import { listPublish } from "../api/ai/publish";
import { getIndexStatistics, listVideoTask } from "../api/ai/videoTask";
import CompanyInfo from "./home/CompanyInfo.vue";
import vueScrolling from "@/components/vueScrolling/index.vue";
import EventBus from "@/utils/EventBus";
import { listNotice } from "../api/system/notice";

export default {
  // name: "Index",
  dicts: ["publish_time_type", "issue_type", "social_platform"],
  components: {
    TabItem,
    CompanyInfo,
    vueScrolling,
  },
  data() {
    return {
      quota: {},
      workStatistics: {},
      workLoading: false,
      buttons: [
        {
          name: this.$t("workbench.videoByText"),
          path: "/videoManager/aiCrosscutting",
          quota: "",
          use: "",
          des: this.$t("workbench.videoByTextTip"),
          hover: false,
          icon: "icon-shipin",
          bgColor: "#EAF8FA",
          color: "#35C2CF",
        },
        {
          name: this.$t("workbench.AIVideo"),
          path: "/videoManager/make_video",
          quota: "",
          use: "",
          des: this.$t("workbench.AIVideoTip"),
          hover: false,
          icon: "icon-aihunjian1",
          bgColor: "#EEF1FD",
          color: "#5978EC",
        },
        {
          name: this.$t("workbench.videoTranslation"),
          path: "/ai/videoTranslation",
          quota: "",
          use: "",
          des: this.$t("workbench.videoTranslationTip"),
          hover: false,
          icon: "icon-danyuanpeizhi",
          bgColor: "#FFEBEA",
          color: "#FF4239",
        },
        {
          name: this.$t("workbench.operationsStrategy"),
          path: "/operationStrategy",
          quota: "",
          use: "",
          des: this.$t("workbench.operationsStrategyTip"),
          hover: false,
          icon: "icon-jihua",
          bgColor: "#FFE9F9",
          color: "#FF24C4",
        },
        {
          name: this.$t("workbench.releaseCommunity"),
          path: "/infoStream",
          quota: "",
          use: "",
          des: this.$t("workbench.releaseCommunityTip"),
          hover: false,
          icon: "icon-fabuliu",
          bgColor: "#FFE9F9",
          color: "#FFA128",
        },
        {
          name: this.$t("workbench.messageInteraction"),
          path: "/message",
          quota: "",
          use: "",
          des: this.$t("workbench.messageInteractionTip"),
          hover: false,
          icon: "icon-xiaoxi",
          bgColor: "#F6EAFF",
          color: "#A735FF",
        },
      ],
      accountList: [], //账号列表
      account: "", //当前账号
      loading: false, //发布的loading
      // loadingPublishNum: false,//发布数量的loading
      // publishNum:{}, // 发布对应的数量
      publish: [],
      loadingVideo: false, //视频的loading
      videoStatistics: {}, //视频统计
      videos: [],
      sales: [
        {
          content: "专一感源于整齐划一",
          num: 1000,
          open: true,
        },
        {
          content: "好设计是诚实的",
          num: 1300,
          open: false,
        },
        {
          content: "好设计是尽可能的无设计",
          num: 3400,
          open: true,
        },
        {
          content: "割草机视频",
          num: 1234,
          open: true,
        },
        {
          content: "塑像本来我只是把不要的部分去掉",
          num: 1007,
          open: true,
        },
        {
          content: "好设计是有创意的",
          num: 1505,
          open: true,
        },
      ],
      notice: [
        {
          type: "1",
          content: "新用户优惠活动开启啦！",
          time: "2023-08-01",
        },
        {
          type: "2",
          content: "抖音账号审核不通过",
          time: "2023-07-01",
        },
        {
          type: "3",
          content: "视频生成失败",
          time: "2023-03-01",
        },
        {
          type: "1",
          content: "新用户优惠活动开启啦！",
          time: "2022-08-01",
        },
        {
          type: "2",
          content: "抖音账号审核不通过",
          time: "2022-07-01",
        },
        {
          type: "3",
          content: "视频生成失败",
          time: "2022-03-01",
        },
      ],
      help: [
        {
          url: require("@/assets/images/workbench/help_product.png"),
          name: this.$t("workbench.productSummary"),
        },
        {
          url: require("@/assets/images/workbench/help_usage.png"),
          name: this.$t("workbench.userGuide"),
        },
        {
          url: require("@/assets/images/workbench/help_package.png"),
          name: this.$t("workbench.planDescription"),
        },
        {
          url: require("@/assets/images/workbench/help_product_info.png"),
          name: this.$t("workbench.productNews"),
        },
      ],
      user: {},
      infoVisible: false,
      socialType: "tiktok",
      communityList: [
        {
          avatar: 10,
          time: "刚刚",
          name: "Laura Garner",
          platform: "Tiktok",
          content1: "通过平台发布一条视频到",
          content2:
            "《我们的割草机最新的型号问世啦！！家人们最新型号解说查看》",
        },
        {
          avatar: 3,
          time: "3分钟前",
          name: "Daisy Delgado",
          platform: "Youtube",
          content1: "通过平台发布一条视频到",
          content2: "《兰香企业宣传视频》",
        },
        {
          avatar: 4,
          time: "5分钟前",
          name: "Keith Gonzalez",
          platform: "Instagram",
          content1: "通过平台发布一条视频到",
          content2: "《文具总动发布会V2.2》点赞突破 1w",
        },
        {
          avatar: 15,
          time: "2分钟前",
          name: "Sara Lawson",
          platform: "Tiktok",
          content1: "通过平台发布一条视频到",
          content2: "《创新科技家用机器人展示》",
        },
        {
          avatar: 7,
          time: "10分钟前",
          name: "Peter Simmons",
          platform: "Youtube",
          content1: "通过平台发布一条视频到",
          content2: "《城市风景航拍合集》",
        },
        {
          avatar: 18,
          time: "15分钟前",
          name: "Evelyn Fox",
          platform: "Instagram",
          content1: "通过平台发布一条视频到",
          content2: "《宠物趣味合集》评论数突破 5k",
        },
        {
          avatar: 2,
          time: "20分钟前",
          name: "Chris Jenkins",
          platform: "Tiktok",
          content1: "通过平台发布一条视频到",
          content2: "《街头美食探秘》",
        },
        {
          avatar: 5,
          time: "25分钟前",
          name: "Megan Ortiz",
          platform: "Youtube",
          content1: "通过平台发布一条视频到",
          content2: "《晨间瑜伽教学》",
        },
        {
          avatar: 9,
          time: "刚刚",
          name: "JACK li",
          platform: "Youtube",
          content1: "通过平台发布一条视频到",
          content2: "《冬季时尚穿搭指南》",
        },
        {
          avatar: 14,
          time: "1分钟前",
          name: "Lily Patterson",
          platform: "Youtube",
          content1: "通过平台发布一条视频到",
          content2: "《最受欢迎的宠物玩具展示》点赞数突破 2w",
        },
        {
          avatar: 6,
          time: "3分钟前",
          name: "Lucas Murphy",
          platform: "Youtube",
          content1: "通过平台发布一条视频到",
          content2: "《美丽的山川与河流航拍》",
        },
        {
          avatar: 17,
          time: "8分钟前",
          name: "Sophia Wells",
          platform: "Instagram",
          content1: "通过平台发布一条视频到",
          content2: "《美食节之旅》评论数达到 1k",
        },
        {
          avatar: 12,
          time: "刚刚",
          name: "Jackson Flores",
          platform: "Tiktok",
          content1: "通过平台发布一条视频到",
          content2: "《DIY家庭装饰教程》",
        },
        {
          avatar: 20,
          time: "2分钟前",
          name: "Emma Bell",
          platform: "Youtube",
          content1: "通过平台发布一条视频到",
          content2: "《城市夜景探索》播放量突破 10w",
        },
        {
          avatar: 11,
          time: "6分钟前",
          name: "Grace White",
          platform: "Instagram",
          content1: "通过平台发布一条视频到",
          content2: "《自然风光摄影》",
        },
        {
          avatar: 1,
          time: "刚刚",
          name: "Oscar Ford",
          platform: "Tiktok",
          content1: "通过平台发布一条视频到",
          content2: "《无人机拍摄的惊艳景色》",
        },
      ],
      displayedList: [],
      noticeList: [
        // {
        //   title:
        //     "海外社交媒体平台内容的“保质期”与推广节奏：如何获得更好的效果？",
        //   url: "https://mp.weixin.qq.com/s/RvD2jEtPk1Y0G9jLm7xSiQ",
        // },
        // {
        //   title: "出海流量密码！InfoPub带你一键打通十款海外主流社交平台",
        //   url: "https://mp.weixin.qq.com/s/ayM8JB5AXClKm3UPPsZqKQ",
        // },
        // {
        //   title: "跨境出海新纪元：AI智能如何重塑全球化竞争格局？",
        //   url: "https://mp.weixin.qq.com/s/xN1tnGsDB2IoeNETy8i66Q",
        // },
        // {
        //   title: "AI挑西瓜：每一口都是甜蜜暴击",
        //   url: "https://mp.weixin.qq.com/s/dP4ZPto7057eRSElJ5Muyg",
        // },
      ],
      aiWorkshopList: [
        {
          title: this.$t("workbench.aiVideoTranslation"),
          content: this.$t("workbench.translationContent"),
          icon: "icon-shipinfanyi",
          path: "/ai/videoTranslation",
        },
        {
          title: this.$t("workbench.AIFaceChanging"),
          content: this.$t("workbench.faceChangingContent"),
          icon: "icon-AIhuanlian",
          path: "/ai/faceSwap",
        },
        {
          title: this.$t("workbench.aiSubtitleRemoval"),
          content: this.$t("workbench.subtitleRemovalContent"),
          icon: "icon-shipinquzimu",
          path: "/ai/unsubtitle",
        },
        {
          title: this.$t("workbench.aISlicing"),
          content: this.$t("workbench.aISlicingContent"),
          icon: "icon-shipinqiepian",
          path: "/ai/aiSlicing",
        },
        {
          title: this.$t("workbench.aiVideoTransfer"),
          content: this.$t("workbench.videoTransferContent"),
          icon: "icon-shipinbanyun",
          path: "/ai/videoCarry",
        },
      ],
    };
  },
  created() {
    this.getQuota();
    this.getWorkStatistics();
    this.getAccountList();
    this.getPublishList();
    this.getVideoList();
    this.getUser();
    this.getNoticeList();
    EventBus.$emit("notice-refresh");
  },
  methods: {
    getNoticeList() {
      listNotice().then((response) => {
        this.noticeList = response.data;
      });
    },
    showNotice(item) {
      window.open(item.noticeContent, "_blank");
    },
    goVideoManager(item) {
      if (item.taskType == 3) {
        this.goTarget("/videoManager/aiCrosscutting");
      } else {
        this.goTarget("/videoManager/videoManager");
      }
    },
    getUser() {
      myMerchant().then((response) => {
        this.user = response.data || {};
        if (
          !this.user ||
          (!this.user.industryCategory &&
            !this.user.companyName &&
            !this.user.brand &&
            !this.user.product)
        ) {
          this.$confirm(this.$t("tip.infoPrompt"), this.$t("tip.tip"), {
            confirmButtonText: this.$t("tip.completeNow"),
            cancelButtonText: this.$t("tip.later"),
            // showCancelButton: false,
            type: "warning",
            showClose: false,
            distinguishCancelAndClose: true, // 重要，设置为true才会把右上角X和取消区分开来
            closeOnClickModal: false,
          })
            .then(() => {
              this.infoVisible = true;
              this.$nextTick().then((rs) => {
                if (this.user && this.user.industryCategory) {
                  this.user.industryCategorys =
                    this.user.industryCategory.split(",");
                }
                this.$refs.companyInfo.init(this.user);
              });
            })
            .catch(() => {});
        }
      });
    },
    // 计算图标的 class，根据 today 和 yesterday 的差值返回不同图标
    getIcon(todayAddCount, yesterdayAddCount) {
      const diff = todayAddCount || 0 - yesterdayAddCount || 0;
      return diff > 0 ? "icon-up" : diff < 0 ? "icon-down" : "icon-neutral";
    },
    // 计算差值并取正数
    getDiff(todayAddCount, yesterdayAddCount) {
      return Math.abs(todayAddCount || 0 - yesterdayAddCount || 0);
    },
    getWorkStatistics() {
      this.workLoading = true;
      userStatistics()
        .then((response) => {
          this.workStatistics = response.data || {};
        })
        .finally(() => {
          this.workLoading = false;
        });
    },
    goTarget(href) {
      if (href) {
        this.$router.push({ path: href });
      } else {
        this.$modal.alert(this.$t("workbench.notOpen"));
      }
    },
    //获取配额
    getQuota() {
      getQuota().then((response) => {
        this.quota = response.data || {};
        this.buttons.forEach((button) => {
          switch (button.path) {
            case "/account":
              button.quota = this.quota.socialCount;
              button.use = this.quota.useSocialCount;
              break;
            case "/material":
              button.quota = this.quota.fileSize;
              button.use = this.quota.useFileSize;
              break;
            case "/videoManager/videoManager":
              button.quota = this.quota.videoLength;
              button.use = this.quota.useVideoLength;
              break;
            case "/issueManager":
              button.quota = this.quota.publishCount;
              button.use = this.quota.usePublishCount;
              break;
            default:
              button.quota = "";
              button.use = "";
          }
        });
      });
    },
    /** 账号列表 */
    getAccountList() {
      // listAccount().then(response => {
      //   this.accountList = response.rows;
      //   if(this.accountList.length>0){
      //     this.account = this.accountList[0].id
      //     this.getPublishList()
      //   }
      // })
    },
    getPublishList() {
      // if(!this.account){
      //   this.getAccountList()
      //   return
      // }
      this.loading = true;
      listPublish({
        pageNum: 1,
        pageSize: 4,
        status: 2, //发布成功
        // socialType: this.socialType,
        // socialId: this.account,
      })
        .then((response) => {
          this.publish = response.rows;
        })
        .finally(() => {
          this.loading = false;
        });
      // this.loadingPublishNum = true
      // getAccount(this.account).then(response => {
      //   this.publishNum = response.data || {}
      // }).finally(() => {
      //   this.loadingPublishNum = false
      // })
    },
    getVideoList() {
      this.getIndexStatistics();
      this.loadingVideo = true;
      listVideoTask({
        pageNum: 1,
        pageSize: 4,
        status: 3, //生成成功
      })
        .then((response) => {
          this.videos = response.rows;
        })
        .finally(() => {
          this.loadingVideo = false;
        });
    },
    getIndexStatistics() {
      getIndexStatistics().then((response) => {
        this.videoStatistics = response.data || {};
      });
    },
    getNoticeType(type) {
      if (type == "1") {
        return this.$t("workbench.events");
      } else if (type == "2") {
        return this.$t("workbench.messages");
      } else if (type == "3") {
        return this.$t("workbench.notifications");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  padding: 20px;
}
.home-left {
  width: 70%;
  padding-top: 16px;
}
.home-right {
  width: 30%;
  margin-left: 18px;
}
.matrix-container {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  border: 1px solid #e5e7eb;
  padding: 32px 24px;
}
.fans-title {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: center;
  font-style: normal;
}
.fans-num {
  height: 38px;
  font-family: BebasNeue;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 38px;
  text-align: left;
  font-style: normal;
  margin-top: 24px;
}
.task-bg-border {
  border: 1px solid #e5e7eb;
  border-radius: 14px;
  padding: 0 0 16px;
  margin: 16px 16px 0;
}
.task-bg {
  padding: 0 16px 24px;
}
.notice-bg-border {
  border: 1px solid #e5e7eb;
  border-radius: 14px;
  padding: 10px 19px;
  margin: 16px 16px 0;
  line-height: 30px;
  i {
    font-size: 20px;
  }
}
.custom-radio-group ::v-deep.el-radio__input {
  display: none; /* 隐藏原点 */
}
.custom-radio-group .el-radio__label {
  display: flex; /* 使内容水平排列 */
  align-items: center; /* 垂直居中 */
}
.radio-social {
  margin-right: 20px;
}
.el-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.el-col {
  align-items: stretch; /* 让子元素在垂直方向上拉伸，保持高度一致 */
  margin-bottom: 16px;
}
.blue-line {
  width: 4px;
  height: 16px;
  background: #0156ff;
  border-radius: 2px;
  margin-right: 4px;
}
.home-title {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.work-title {
  height: 22px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.link-type {
  height: 20px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-decoration-line: underline;
}
.el-input__inner {
  background: transparent;
  border: none;
}

.publish-containerContent {
  height: 134px;
  background: #ffffff;
  border-radius: 14px;
  border: 1px solid #dfdfdf;
  padding: 16px 16px 8px 10px;
  cursor: pointer;
  .point {
    width: 8px;
    height: 8px;
    background-color: #006fee;
    border-radius: 8px;
    margin-top: 7px;
  }
  .post-content {
    height: 40px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-left: 10px;
  }
  .publish-status {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #00a870;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin-top: 20px;
    .iconfont {
      font-size: 14px;
      margin-right: 4px;
    }
  }
  .time {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-left: 19px;
  }
  .icon-gray-bg {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #f1f1f1;
    border: 1px solid #ffffff;
    margin-right: 4px;
    .iconfont {
      font-size: 12px;
    }
  }
}
// .mediaBox {
//   display: flex;
//   gap: 16px; /* 设置间距 */
// }
.video-container {
  // flex: 0 0 calc(25% - 16px); /* 每个 item 占 20% 的宽度，减去间距 */
  height: 100%;
  padding: 8px 10px;
  cursor: pointer;
  overflow: hidden;
  background: #f4f4f5;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  .video-cover {
    width: 76px;
    height: 118px;
    border-radius: 4px;
    object-fit: cover;
  }
  .video-right {
    flex: 1; /* 右侧信息区域占据剩余空间 */
    overflow: hidden; /* 确保文本不溢出 */
    margin-left: 9px;
  }
  .video-title {
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
  }
  .video-content {
    height: 42px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 14px;
    text-align: left;
    font-style: normal;
    margin-top: 12px;
  }
  .time {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.26);
    line-height: 20px;
    font-weight: 400;
  }
}

.dynamic-scroll-container {
  height: 430px; /* 设置容器高度 */
  overflow: hidden; /* 隐藏溢出部分 */
  position: relative; /* 为绝对定位提供参考 */
}

.scroll-list {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
}

.carousel-container {
  height: 30px; /* 设置合适的高度 */
  width: 100%; /* 使用100%宽度 */
  margin-left: 5px;
}
.notice {
  .notice-title {
    font-size: 14px;
    font-weight: 500;
    color: #1d2129;
    line-height: 30px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  i {
    color: #ff5215;
    font-size: 14px;
    margin-right: 6px;
  }
  .point-blue {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #0156ff;
    margin-left: 5px;
    margin-right: 11px;
    display: inline-block;
  }
  .notice-hot {
    min-width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    background: #ff9406;
    color: white;
    border-radius: 4px;
    text-align: center;
    margin-left: 5px;
  }
  .notice-new {
    min-width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    background: #ff3852;
    color: white;
    border-radius: 4px;
    text-align: center;
    margin-left: 5px;
  }
}

.workshop-box {
  display: flex;
  justify-content: space-between;
  .workshop-item {
    position: relative;
    width: 19%;
    height: 124px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #ededed;
    background: transparent;
    cursor: pointer;
    .workshop-icon-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .open-text {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #71717a;
        line-height: 17px;
        text-align: left;
        font-style: normal;
        .icon-xiangyoujiantou {
          font-size: 10px;
          color: #71717a;
        }
      }
      .iconfont {
        font-size: 22px;
        color: #fff;
      }
      .workshop-icon {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .workshop-icon1 {
        background: linear-gradient(180deg, #dcaeff 0%, #8c00d4 100%);
        box-shadow: 0px 2px 6px 0px rgba(140, 0, 212, 0.3);
      }
      .workshop-icon2 {
        background: linear-gradient(180deg, #aefff3 0%, #00d4ab 100%);
        box-shadow: 0px 2px 6px 0px rgba(0, 212, 171, 0.3);
      }
      .workshop-icon3 {
        background: linear-gradient(180deg, #9fcaff 0%, #439bff 100%);
        box-shadow: 0px 2px 6px 0px rgba(67, 155, 255, 0.3);
      }
      .workshop-icon4 {
        background: linear-gradient(180deg, #ffddde 0%, #ff3d4a 100%);
        box-shadow: 0px 2px 6px 0px rgba(255, 61, 74, 0.3);
      }
      .workshop-icon5 {
        background: linear-gradient(180deg, #ffe6cc 0%, #ff971f 100%);
        box-shadow: 0px 2px 6px 0px rgba(255, 151, 31, 0.3);
      }
    }
    .workshop-title {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 14px;
      color: #464a5a;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-top: 8px;
    }
    .workshop-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #8289a3;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      margin-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .workshop-item:hover {
    border: 1px solid #0156ff;
  }
  .workshop-item1 {
    position: relative;
  }
  .workshop-item1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/aiWorkshop/01.png");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.05;
  }
  .workshop-item2 {
    position: relative;
  }
  .workshop-item2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/aiWorkshop/02.png");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.05;
  }
  .workshop-item3 {
    position: relative;
  }
  .workshop-item3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/aiWorkshop/03.png");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.05;
  }
  .workshop-item4 {
    position: relative;
  }
  .workshop-item4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/aiWorkshop/04.png");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.05;
  }
  .workshop-item5 {
    position: relative;
  }
  .workshop-item5::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/aiWorkshop/05.png");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.05;
  }
}
</style>

