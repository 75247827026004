<template>
  <div
    class="list-item flex-row-center"
    @click.stop="toggleSelection"
    @mouseover="mouseoverItem"
    @mouseleave="mouseleaveItem"
    :style="{ zIndex: item.hover ? 10 : 'auto' }"
  >
    <!-- 视频封面 -->
    <div class="video-container f-shrink">
      <video
        :src="item.videoUrl"
        alt="video"
        class="video-list-cover"
      />
      <span
        class="video-list-duration"
        v-if="item.videoLength && item.videoLength>0"
      >{{ secondsFormatTime(item.videoLength) }}</span>
    </div>
    <!-- 视频信息 -->
    <div class="f-grow video-right">
      <div
        class="video-text line-2"
        :class="item.hover||item.selected?'video-text-hover':''"
      >
        {{ item.postContent }}
      </div>
      <div class="nums flex-row-center">
        <i class="iconfont icon-dianzan"></i> {{ item.likes }}
        <i class="iconfont icon-pinglun ml16"></i> {{ item.comments }}
        <i class="iconfont icon-zhuanfa ml16"></i> {{ item.shares }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  components: {},
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {},
      addingTag: false,
      newTag: "",
      mediaUrl: {},
    };
  },
  watch: {
    video: {
      handler(newVal, oldVal) {
        this.item = { ...newVal };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleSelection() {
      this.$emit("view-click", this.item);
    },
    mouseleaveItem() {
      this.$set(this.item, "hover", false);
    },
    mouseoverItem() {
      this.$set(this.item, "hover", true);
    },
  },
};
</script>

<style scoped lang="scss">
.list-item {
  padding: 16px 0;
  background: white;
  overflow: hidden;
  z-index: auto;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
}
// .list-item:hover {
//   background: #f0f3fa;
// }
.video-container {
  width: 120px;
  height: 68px;
  position: relative;
}
.video-list-cover {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0.7;
  object-fit: cover; /* 确保视频不会拉伸 */
}
.video-list-duration {
  position: absolute;
  right: 8px;
  top: 8px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  color: white;
  border: 1px solid white;
  height: 20px;
  padding: 3px 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  font-style: normal;
}
.video-right {
  margin-left: 12px;
}
.video-text {
  height: 32px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 16px;
  text-align: left;
  font-style: normal;
}
.video-text-hover {
  color: #002ec7;
}
.nums {
  width: 100%;
  margin-top: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  .iconfont {
    color: #e2e3e7;
  }
}
</style>
