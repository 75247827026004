var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "publicDialog",
      attrs: {
        width: "50%",
        visible: _vm.localVisible,
        title: _vm.$t("register.completeInformation"),
        "before-close": _vm.handleBeforeClose,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.$t("register.completeInformation"))),
          ]),
          _c("i", {
            staticClass: "el-icon-close",
            on: { click: _vm.handleCancel },
          }),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "registerForm",
          staticClass: "register-form",
          attrs: {
            model: _vm.registerForm,
            rules: _vm.registerRules,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("register.companyName"),
                prop: "companyName",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  autocomplete: "off",
                  maxlength: "50",
                  placeholder: _vm.$t("tip.input"),
                },
                model: {
                  value: _vm.registerForm.companyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.registerForm, "companyName", $$v)
                  },
                  expression: "registerForm.companyName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                label: _vm.$t("register.industry"),
                prop: "industryCategorys",
              },
            },
            [
              _c("el-cascader", {
                staticStyle: { width: "100%" },
                attrs: {
                  "show-all-levels": false,
                  props: _vm.props,
                  options: _vm.industryCategoryOptions,
                },
                model: {
                  value: _vm.registerForm.industryCategorys,
                  callback: function ($$v) {
                    _vm.$set(_vm.registerForm, "industryCategorys", $$v)
                  },
                  expression: "registerForm.industryCategorys",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("register.brand"), prop: "brand" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  autocomplete: "off",
                  maxlength: "50",
                  placeholder: _vm.$t("register.brandTip"),
                },
                model: {
                  value: _vm.registerForm.brand,
                  callback: function ($$v) {
                    _vm.$set(_vm.registerForm, "brand", $$v)
                  },
                  expression: "registerForm.brand",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("register.product"), prop: "product" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  autocomplete: "off",
                  maxlength: "50",
                  placeholder: _vm.$t("register.productTip"),
                },
                model: {
                  value: _vm.registerForm.product,
                  callback: function ($$v) {
                    _vm.$set(_vm.registerForm, "product", $$v)
                  },
                  expression: "registerForm.product",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c(
            "el-button",
            {
              staticClass: "cancelBtn btnWidth120",
              on: { click: _vm.handleCancel },
            },
            [_vm._v(_vm._s(_vm.$t("skip")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "submitBtn btnWidth120",
              attrs: { loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v(_vm._s(_vm.$t("submit")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }