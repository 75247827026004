<template>
  <div class="faddish-container">
    <div class="flex-row-center">
      <div
        class="f-title flex-row-center"
        style="width: 25%;padding: 0 24px;"
      >{{ $t('faddish.breakdown') }}</div>
      <div
        class="flex-center"
        style="width: 75%;"
      >
        <div
          class="flex-row-center"
          style="width: 70%;"
        >
          <div
            class="f-grow mr5"
            style="padding: 0 24px;"
          >
            <el-input
              class="account-input"
              v-model="queryParams.searchValue"
              :placeholder="$t('faddish.searchPlaceholder')"
              @keyup.enter.native="handleQuery"
              clearable
            >
              <i
                slot="prefix"
                class="el-input__icon iconfont icon-sousuotubiao"
                @click="handleQuery"
              ></i>
            </el-input>
          </div>
          <el-tooltip
            class="f-shrink"
            effect="dark"
            :content="$t('refresh')"
            placement="top"
          >
            <i
              class="iconfont icon-a-danyuanpeizhi6"
              @click="refresh"
            ></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div
      class="faddish-content flex-row"
      v-loading="loading"
    >
      <div class="faddish-left">
        <list-item
          v-for="(video, index) in list"
          :key="index"
          :video="video"
          @view-click="handleView"
        />
        <el-empty
          v-if="list.length==0"
          :image-size="200"
        ></el-empty>
      </div>
      <div class="faddish-right flex-column-center">
        <div style="width: 70%;">
          <div
            v-if="!currentItem"
            class="content-empty flex-center"
          >
            {{ $t('faddish.clickToView') }}
          </div>
          <detail
            v-else
            ref="drawerDetail"
            :item="currentItem"
            @make-video="makeVideo"
          ></detail>
        </div>
      </div>
    </div>
    <mix-dialog
      ref="mix"
      :visible.sync="mixVisible"
      @make-video="makeVideo"
    />
    <add-text-dialog
      ref="textDialog"
      :visible.sync="textVisible"
      :title="$t('copywriting.new')"
      :label-list="labelList"
      :materialTypeList="materialTypeList"
    />
  </div>
</template>
<script>
import { listPopular } from "../../api/ai/faddish";
import ListItem from "./components/ListItem";
import Detail from "./detail";
import MixDialog from "./mixDialog";
import AddTextDialog from "../../components/dialog/addTextDialog.vue";
import { listLabel } from "../../api/system/label";
import { listMaterialType } from "../../api/ai/materialType";
export default {
  name: "Faddish",
  components: {
    ListItem,
    Detail,
    MixDialog,
    AddTextDialog,
  },
  data() {
    return {
      queryParams: {
        // pageNum: 1,
        // pageSize: 24,
        searchValue: "",
      },
      loading: false,
      list: [],
      // 总条数
      total: 0,
      mixVisible: false,
      textVisible: false, //文案弹窗
      labelList: [], //标签
      materialTypeList: [], //素材分类
      currentItem: null,
    };
  },
  created() {
    this.getList();
    this.getLabels();
    this.getMaterialTypeList();
  },
  methods: {
    //爆款列表
    getList() {
      this.loading = true;
      listPopular(this.queryParams)
        .then((response) => {
          this.list = response.rows;
          this.total = response.total;
          if (this.list && this.list.length > 0) {
            this.currentItem = this.list[0];
            this.list.forEach((item) => {
              item.hover = false;
              item.selected = false;
              if (item.postContent) {
                item.postContent = item.postContent.split("#")[0].trim();
              }
              if (item.tags) {
                item.labels = JSON.parse(item.tags);
              } else {
                item.labels = [];
              }
              console.log(item.labels);
            });
            this.$set(this.list, 0, {
              ...this.list[0],
              selected: true,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 标签列表
    getLabels() {
      listLabel().then((response) => {
        this.labelList = response.rows;
      });
    },
    //素材分类列表
    getMaterialTypeList() {
      listMaterialType().then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.materialTypeList = JSON.parse(
            JSON.stringify(response.data)
          ).filter((item) => item.type !== "system");
          this.materialTypeList.unshift({
            id: 0,
            name: this.$t("basicInfo.materia"),
            type: "system",
          });
        }
      });
    },
    // 查看详情
    handleView(row) {
      this.currentItem = row;
      this.list = this.list.map((v) => ({
        ...v,
        selected: false,
      }));

      // 选择当前点击的项目
      const index = this.list.findIndex((v) => v.id === row.id);
      if (index !== -1) {
        this.$set(this.list, index, {
          ...this.list[index],
          selected: true,
        });
      }
    },
    // 视频制作——跳转到文生视频第二步
    makeVideo(detail) {
      console.log("解析的文案", detail.videoContentList);
      sessionStorage.setItem(
        "editManuscriptsData",
        JSON.stringify([
          {
            // title: detail.postContent,
            title: "",
            paragraphList: detail.videoContentList,
          },
        ])
      );
      this.$router.push({
        path: `/newCutting/${detail.id}`,
        query: {
          isFaddish: true,
        },
      });
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    refresh() {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.faddish-container {
  background: white;
  padding: 24px;
}
.faddish-content {
  height: calc(100vh - 150px);
  padding: 0 24px 24px;
}
.f-title {
  height: 40px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.faddish-left {
  width: 25%;
  overflow: auto;
}
.faddish-right {
  width: 75%;
  overflow: auto;
  .content-empty {
    width: 100%;
    height: 300px;
  }
}
.faddish-right::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
