<!-- 文案解析 -->
<template>
  <el-dialog
    class="publicDialog mixDialog"
    :visible.sync="localVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="50%"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ $t('faddish.copyAnalysis') }}</div>
        <i
          class="el-icon-close"
          @click="close"
        ></i>
      </div>
    </div>
    <div
      class="accountHeight flex-column"
      v-loading="loading"
    >
      <div
        class="flex-column mix-scene"
        v-for="(item, index) in detail.videoContentList"
        :key="index"
      >
        <span class="mix-scene-num f-shrink">{{ $t('faddish.scenes') }}{{ index + 1 }}{{ $t('faddish.commentary') }}：</span>
        <span class="mix-scene-content">{{ item }}</span>
      </div>
    </div>
    <div class="flex-center mt20">
      <el-button
        class="cancelBtn btnWidth120"
        @click="handleCancel"
      >{{ $t('cancel') }}</el-button>
      <el-button
        class="submitBtn btnWidth120"
        :disabled="loading"
        type="primary"
        @click="submit"
      >{{ $t('faddish.createVideo') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getPopularDetail } from "../../api/ai/faddish";

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localVisible: this.visible,
      id: undefined,
      loading: false,
      detail: {},
    };
  },
  created() {},
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
  },
  methods: {
    init(id) {
      this.id = id;
      this.getDetail();
    },
    getDetail() {
      this.loading = true;
      getPopularDetail(this.id)
        .then((res) => {
          this.detail = res.data;
          if (this.detail.postContent) {
            this.detail.postContent = this.detail.postContent
              .split("#")[0]
              .trim();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.close();
    },
    close() {
      this.localVisible = false;
      this.$emit("update:visible", false);
    },
    submit() {
      if (
        !this.detail.videoContentList ||
        this.detail.videoContentList.length === 0
      ) {
        this.$modal.msgError(this.$t('faddish.parseFail'));
        return;
      }
      this.$emit("make-video", this.detail);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.mixDialog {
  .accountHeight {
    height: 380px;
    max-height: 380px;
    overflow-y: auto;
    /* 隐藏滚动条 */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    .mix-scene {
      margin-bottom: 24px;
      .mix-scene-num {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #003bff;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }
      .mix-scene-content {
        width: 100%;
        background: #f0f3fa;
        border-radius: 12px;
        padding: 12px 16px;
        font-family: PingFang-SC, PingFang-SC;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        margin-top: 8px;
      }
    }
  }
  .accountHeight::-webkit-scrollbar {
    display: none; /* Chrome, Safari 和 Edge */
  }
}
</style>
