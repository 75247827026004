var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "faddish-detail",
    },
    [
      _c("div", { staticClass: "flex-column detail-left" }, [
        _c("video", {
          ref: "videoPlayer",
          staticClass: "detail-video",
          attrs: { controls: "", src: _vm.detail.videoUrl },
        }),
        _c("div", { staticClass: "detail-text" }, [
          _vm._v(_vm._s(_vm.detail.postContent)),
        ]),
        _c(
          "div",
          { staticClass: "detail-topic flex-row f-wrap" },
          _vm._l(_vm.detail.labels, function (item, index) {
            return _c("div", { key: index, staticClass: "mr5 mb5" }, [
              _vm._v("#" + _vm._s(item)),
            ])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "flex-column detail-right" }, [
        _c("span", { staticClass: "detail-title mt16" }, [
          _vm._v(_vm._s(_vm.$t("faddish.author"))),
        ]),
        _c(
          "div",
          { staticClass: " mt16 flex-row-center" },
          [
            _c("el-avatar", {
              attrs: { size: 56, src: _vm.detail.userAvatar },
            }),
            _c("div", { staticClass: "flex-column ml16" }, [
              _c("span", { staticClass: "\n        detail-title" }, [
                _vm._v("Caleb Webster"),
              ]),
              _c(
                "span",
                {
                  staticClass: "detail-normal",
                  staticStyle: { "margin-top": "14px" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("faddish.fans")) +
                      "：" +
                      _vm._s(_vm.detail.fansCount)
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
        _vm.detail.labels && _vm.detail.labels.length > 0
          ? _c("div", { staticClass: "detail-title mt32" }, [
              _vm._v(_vm._s(_vm.$t("faddish.tags"))),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "detail-labels" },
          _vm._l(_vm.detail.labels, function (tag, index) {
            return _c(
              "el-tag",
              {
                key: index,
                staticClass: "label-normal",
                class: _vm.getLabelClass(index),
              },
              [_vm._v(" #" + _vm._s(tag) + " ")]
            )
          }),
          1
        ),
        _c("div", { staticClass: "detail-title mt32" }, [
          _vm._v(_vm._s(_vm.$t("faddish.basicInfo"))),
        ]),
        _c("div", { staticClass: "detail-statistics flex-row-center mt16" }, [
          _c("div", { staticClass: "flex-column-center" }, [
            _c("div", { staticClass: "detail-num" }, [
              _vm._v(_vm._s(_vm.detail.likes || 0)),
            ]),
            _c("div", { staticClass: "detail-num-name" }, [
              _vm._v(_vm._s(_vm.$t("faddish.likes"))),
            ]),
          ]),
          _c("div", { staticClass: "flex-column-center" }, [
            _c("div", { staticClass: "detail-num" }, [
              _vm._v(_vm._s(_vm.detail.shares || 0)),
            ]),
            _c("div", { staticClass: "detail-num-name" }, [
              _vm._v(_vm._s(_vm.$t("faddish.shares"))),
            ]),
          ]),
          _c("div", { staticClass: "flex-column-center" }, [
            _c("div", { staticClass: "detail-num" }, [
              _vm._v(_vm._s(_vm.detail.comments || 0)),
            ]),
            _c("div", { staticClass: "detail-num-name" }, [
              _vm._v(_vm._s(_vm.$t("faddish.comments"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "detail-time detail-gray" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("faddish.publishTime")) +
              "：" +
              _vm._s(_vm.detail.publishTime) +
              " "
          ),
        ]),
        _c("div", { staticClass: "detail-interaction detail-gray" }, [
          _vm._v(" " + _vm._s(_vm.$t("faddish.interactions")) + "： "),
          _c("i", { staticClass: "iconfont icon-dianzan" }),
          _vm._v(" " + _vm._s(_vm.detail.likes) + " "),
          _c("i", { staticClass: "iconfont icon-pinglun ml16" }),
          _vm._v(" " + _vm._s(_vm.detail.comments) + " "),
          _c("i", { staticClass: "iconfont icon-zhuanfa ml16" }),
          _vm._v(" " + _vm._s(_vm.detail.shares) + " "),
          _c("i", { staticClass: "iconfont icon-quxiaoshoucang ml16" }),
          _vm._v(" " + _vm._s(_vm.detail.collects) + " "),
        ]),
        _c("div", { staticClass: "detail-title mt32" }, [
          _vm._v(_vm._s(_vm.$t("faddish.textBreakdown"))),
        ]),
        _c(
          "div",
          { staticClass: "detail-content flex-column mt16" },
          [
            _vm._l(_vm.detail.videoContentList, function (item, index) {
              return _c("div", { key: index, staticClass: "flex-row mb16" }, [
                _vm._m(0, true),
                _c("span", { staticClass: "scene-num f-shrink" }, [
                  _vm._v(_vm._s(_vm.$t("faddish.scenes")) + _vm._s(index + 1)),
                ]),
                _c("span", { staticClass: "scene-content" }, [
                  _vm._v(_vm._s(item)),
                ]),
              ])
            }),
            _c(
              "div",
              {
                staticClass: "self-end fix-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("make-video", _vm.detail)
                  },
                },
              },
              [
                _c("i", { staticClass: "iconfont icon-chaijie" }),
                _vm._v(" " + _vm._s(_vm.$t("faddish.startMixing")) + " "),
              ]
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-scene flex-center f-shrink" }, [
      _c("i", { staticClass: "iconfont icon-shengyin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }