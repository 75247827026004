// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/nopermi.jpeg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login[data-v-3e9eaf47] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  height: 100%;\n  max-width: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n}\n.title[data-v-3e9eaf47] {\n  margin: 0px auto 30px auto;\n  text-align: center;\n  color: #707070;\n}\n.login-form[data-v-3e9eaf47] {\n  border-radius: 6px;\n  background: #ffffff;\n  width: 400px;\n  padding: 25px 25px 5px 25px;\n}\n.login-form .el-input[data-v-3e9eaf47] {\n  height: 38px;\n}\n.login-form .el-input input[data-v-3e9eaf47] {\n  height: 38px;\n}\n.login-form .input-icon[data-v-3e9eaf47] {\n  height: 39px;\n  width: 14px;\n  margin-left: 2px;\n}\n.login-tip[data-v-3e9eaf47] {\n  font-size: 13px;\n  text-align: center;\n  color: #bfbfbf;\n}\n.login-code[data-v-3e9eaf47] {\n  width: 33%;\n  height: 38px;\n  float: right;\n}\n.login-code img[data-v-3e9eaf47] {\n  cursor: pointer;\n  vertical-align: middle;\n}\n.el-login-footer[data-v-3e9eaf47] {\n  height: 40px;\n  line-height: 40px;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  text-align: center;\n  color: #fff;\n  font-family: Arial;\n  font-size: 12px;\n  letter-spacing: 1px;\n}\n.login-code-img[data-v-3e9eaf47] {\n  height: 38px;\n}", ""]);
// Exports
module.exports = exports;
