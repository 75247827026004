<template>
  <div
    class="grid-item"
    @click.stop="toggleSelection"
    @mouseover="mouseoverItem"
    @mouseleave="mouseleaveItem"
    :style="{ zIndex: item.hover ? 10 : 'auto' }"
  >
    <!-- 视频封面 -->
    <div class="left-section">
      <div class="video-grid-cover">
        <video
          :src="item.videoUrl"
          alt="video"
          class="video-grid-cover"
          muted
          playsinline
          loop
          preload="auto"
          ref="videoPlayer"
          @mouseenter="playVideo"
          @mouseleave="pauseVideo"
        />
        <!-- autoplay -->
        <!-- <span
          class="video-grid-duration"
          v-if="item.videoLength&&item.videoLength>0"
        >{{ secondsFormatTime(item.videoLength) }}</span> -->
        <div class="video-text-container flex-column">
          <div
            class="video-text"
            :class="item.hover?'':'line-2'"
          >
            {{ item.postContent }}
          </div>
          <div class="video-grid-labels flex-row-center single-line-ellipsis">
            <span
              v-for="(tag, index) in item.labels"
              :key="index"
              class="mr5"
            >
              #{{ tag }}
            </span>
          </div>
        </div>
        <div
          class="video-grid-play"
          @click.stop="viewMedia(item)"
        >
          <i class="el-icon-caret-right"></i>
        </div>
      </div>
    </div>
    <!-- 视频信息 -->
    <div class="middle-section flex-row-center f-between">
      <!-- <div class="flex-column-center">
        <div class="num-num">{{ item.views }}</div>
        <div class="num-name">播放量</div>
      </div> -->
      <div class="flex-column-center">
        <div class="num-num num-likes">{{ item.likes }}</div>
        <div class="num-name">{{ $t('faddish.likes') }}</div>
      </div>
      <div class="flex-column-center">
        <div class="num-num num-shares">{{ item.shares }}</div>
        <div class="num-name">{{ $t('faddish.shares') }}</div>
      </div>
      <div class="flex-column-center">
        <div class="num-num num-comments">{{ item.comments }}</div>
        <div class="num-name">{{ $t('faddish.comments') }}</div>
      </div>
    </div>
    <!-- 操作按钮 -->
    <div class="right-section flex-row-center f-between">
      <div
        :class="item.hover?'icon-hover':'icon-normal'"
        @click.stop="$emit('view-parse', item)"
      >
        <i class="iconfont icon-chaijie"></i>
        {{ $t('faddish.copyAnalysis') }}
      </div>
      <div
        :class="item.hover?'icon-hover':'icon-normal'"
        @click.stop="$emit('add-material', item)"
      >
        <i class="iconfont icon-tianjiazhisucaiku"></i>
        {{ $t('faddish.addCopy') }}
      </div>
    </div>
    <VideoButtonGrid
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      :url="mediaUrl"
      :type="0"
      :tip="$t('file.filePreview')"
      @closeMedia="closeMediaFun"
    />
  </div>
</template>

<script>
import VideoButtonGrid from "../../../components/VideoButtonGrid/VideoButton.vue";
export default {
  name: "VideoItem",
  components: {
    VideoButtonGrid,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {},
      addingTag: false,
      newTag: "",
      dialogVisible: false,
      mediaUrl: {},
    };
  },
  watch: {
    video: {
      handler(newVal, oldVal) {
        this.item = { ...newVal };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    playVideo() {
      const video = this.$refs.videoPlayer;
      video.play();
    },
    pauseVideo() {
      const video = this.$refs.videoPlayer;
      video.pause();
    },
    closeMediaFun() {
      this.dialogVisible = false;
    },
    viewMedia(item) {
      this.mediaUrl = item.videoUrl;
      this.dialogVisible = true;
    },
    toggleSelection() {
      this.$emit("view-click", this.item);
    },
    mouseleaveItem() {
      if (this.dialogVisible === true) return;
      this.$set(this.item, "hover", false);
    },
    mouseoverItem() {
      if (this.dialogVisible === true) return;
      this.$set(this.item, "hover", true);
    },
  },
};
</script>

<style scoped lang="scss">
.grid-item {
  // max-width: 216px;
  margin: 0 10px 10px 0;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  z-index: auto;
}
.grid-item:hover {
  background: #f0f3fa;
}
.left-section {
  position: relative;
}
.video-grid-cover {
  width: 100%;
  aspect-ratio: 9 / 16; /* 设置宽高比为 4:3 */
  // height: 390px;
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
  object-fit: cover; /* 确保视频不会拉伸 */
}
.video-grid-duration {
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border: 1px solid white;
  height: 20px;
  border-radius: 10px;
  padding: 3px 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  font-style: normal;
}
.video-text-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0px 0px 12px 12px;
  box-sizing: border-box;
}
.video-text {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.video-grid-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  .el-icon-caret-right {
    color: #fff;
    font-size: 24px;
  }
}

.middle-section {
  margin: 24px 8px 0;
}
.num-num {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 14px;
  color: rgba(68, 43, 255, 0.9);
  line-height: 20px;
  font-style: normal;
}
.num-likes {
  color: rgba(1, 86, 255, 0.9);
}
.num-shares {
  color: rgba(255, 125, 0, 0.9);
}
.num-comments {
  color: rgba(0, 0, 0, 0.9);
}
.num-name {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 18px;
  text-align: center;
  font-style: normal;
  margin-top: 8px;
}
.icon-hover {
  background: #0156ff;
  border-radius: 8px;
  color: #ffffff;
  padding: 0 4px;
  cursor: pointer;
}
.icon-normal {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #0156ff;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  padding: 0 4px;
}

.video-grid-labels {
  font-size: 12px;
  color: #0156ff;
  line-height: 14px;
  height: 32px;
}

.right-section {
  height: 24px;
  margin: 16px 8px 10px;
}
</style>
