var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "not-open" }, [
    _c("h1", [_vm._v(_vm._s(_vm.$t("workbench.notOpen")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }