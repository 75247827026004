var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: " home" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                _vm._l(_vm.buttons, function (item, index) {
                  return _c(
                    "el-col",
                    { key: index, attrs: { span: 12 } },
                    [
                      _c("TabItem", {
                        staticStyle: { height: "100%" },
                        attrs: { template: item },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.workLoading,
                    expression: "workLoading",
                  },
                ],
                staticClass: "matrix-container",
              },
              [
                _c("div", { staticClass: "flex-row-center ml20 mr20" }, [
                  _c("img", {
                    staticStyle: { width: "40px", height: "40px" },
                    attrs: { src: _vm.avatar },
                  }),
                  _c(
                    "div",
                    { staticClass: "f-grow ml10" },
                    [
                      _c("div", { staticClass: "work-title mb5" }, [
                        _vm._v(
                          _vm._s(_vm.$t("workbench.accountCount")) +
                            "：" +
                            _vm._s(_vm.workStatistics.socialCount || 0)
                        ),
                      ]),
                      _c(
                        "router-link",
                        { staticClass: "link-type", attrs: { to: "/account" } },
                        [_vm._v(_vm._s(_vm.$t("workbench.newAccount")))]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-row",
                  { staticClass: "mt20", attrs: { gutter: 1 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "flex-column-center",
                          staticStyle: { "border-right": "1px solid #fff" },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: {
                              src: require("../assets/images/workbench/num_fans.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticStyle: { "margin-top": "8px" } }, [
                            _vm._v(_vm._s(_vm.$t("workbench.fanCount"))),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-top": "15px" } },
                            [_vm._v(_vm._s(_vm.workStatistics.fansCount || 0))]
                          ),
                        ]
                      ),
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "flex-column-center",
                          staticStyle: { "border-right": "1px solid #fff" },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: {
                              src: require("../assets/images/workbench/num_works.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticStyle: { "margin-top": "8px" } }, [
                            _vm._v(_vm._s(_vm.$t("workbench.workCount"))),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-top": "15px" } },
                            [_vm._v(_vm._s(_vm.workStatistics.videoCount || 0))]
                          ),
                        ]
                      ),
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "flex-column-center",
                          staticStyle: { "border-right": "1px solid #fff" },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: {
                              src: require("../assets/images/workbench/num_video.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticStyle: { "margin-top": "8px" } }, [
                            _vm._v(_vm._s(_vm.$t("workbench.playCount"))),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-top": "15px" } },
                            [_vm._v(_vm._s(_vm.workStatistics.viewCount || 0))]
                          ),
                        ]
                      ),
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "flex-column-center" }, [
                        _c("img", {
                          staticStyle: { width: "40px", height: "40px" },
                          attrs: {
                            src: require("../assets/images/workbench/num_comment.png"),
                            alt: "",
                          },
                        }),
                        _c("span", { staticStyle: { "margin-top": "8px" } }, [
                          _vm._v(_vm._s(_vm.$t("workbench.commentCount"))),
                        ]),
                        _c("span", { staticStyle: { "margin-top": "15px" } }, [
                          _vm._v(_vm._s(_vm.workStatistics.commentCount || 0)),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "flex-row-center f-between mb10" }, [
        _c(
          "div",
          [
            _c("span", { staticClass: "work-title" }, [
              _vm._v(_vm._s(_vm.$t("workbench.publishTask"))),
            ]),
            _c(
              "el-radio-group",
              {
                staticClass: "custom-radio-group",
                on: { change: _vm.getPublishList },
                model: {
                  value: _vm.socialType,
                  callback: function ($$v) {
                    _vm.socialType = $$v
                  },
                  expression: "socialType",
                },
              },
              _vm._l(_vm.dict.type.social_platform, function (dict) {
                return _c("el-radio", {
                  key: dict.value,
                  attrs: { label: dict.value },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("i", {
                              staticClass: "iconfont mr5 font20",
                              class: [
                                "icon-" + dict.value + "-normal",
                                _vm.socialType == dict.value
                                  ? dict.value
                                  : "unbind",
                              ],
                            }),
                            _vm._v(" " + _vm._s(dict.label) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm.publish.length === 0
        ? _c("el-empty", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { "image-size": 50 },
          })
        : _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            _vm._l(_vm.publish, function (item) {
              return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                _c("div", { staticClass: "publish-containerContent" }, [
                  _c(
                    "div",
                    {
                      staticClass: "publish-container",
                      on: {
                        click: function ($event) {
                          return _vm.goTarget("/issueManager")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-row-center" },
                        [
                          _c("div", { staticClass: "point" }),
                          _c(
                            "span",
                            { staticClass: "pulish-title ml10 f-grow" },
                            [_vm._v(_vm._s(item.taskName))]
                          ),
                          item.status == 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("issueManager.statusDraft"))
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("workbench.publishing"),
                                placement: "top",
                              },
                            },
                            [
                              item.status == 1
                                ? _c("i", { staticClass: "el-icon-loading" })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("workbench.publishSuccess"),
                                placement: "top",
                              },
                            },
                            [
                              item.status == 2
                                ? _c("i", {
                                    staticClass: "el-icon-circle-check",
                                    staticStyle: { color: "#00A0FF" },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("workbench.publishFail"),
                                placement: "top",
                              },
                            },
                            [
                              item.status == 3
                                ? _c("i", {
                                    staticClass: "el-icon-circle-close",
                                    staticStyle: { color: "red" },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: item.postContent,
                            placement: "top",
                          },
                        },
                        [
                          _c("span", { staticClass: "post-content f-grow" }, [
                            _vm._v(_vm._s(item.postContent || "--")),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-row-center f-between time" },
                        [
                          _c("dict-tag", {
                            attrs: {
                              options: _vm.dict.type.publish_time_type,
                              value: item.publishTimeType,
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(item.publishTime))]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            1
          ),
      _c("div", { staticClass: "flex-row-center f-between mt20 mb10" }, [
        _c("span", { staticClass: "work-title" }, [
          _vm._v(_vm._s(_vm.$t("workbench.videoCreation"))),
        ]),
        _c(
          "div",
          [
            _c("span", { staticClass: "ml5" }, [
              _vm._v(
                _vm._s(_vm.$t("workbench.videoMix")) +
                  "：" +
                  _vm._s(_vm.videoStatistics.clip)
              ),
            ]),
            _c("span", { staticClass: "ml5" }, [
              _vm._v(
                _vm._s(_vm.$t("workbench.videoByText")) +
                  "：" +
                  _vm._s(_vm.videoStatistics.aiClip)
              ),
            ]),
            _c("span", { staticClass: "ml5" }, [
              _vm._v(
                _vm._s(_vm.$t("workbench.AIVideo")) +
                  "：" +
                  _vm._s(_vm.videoStatistics.ai)
              ),
            ]),
            _c(
              "el-tooltip",
              {
                staticClass: "ml10",
                attrs: {
                  effect: "dark",
                  content: _vm.$t("refresh"),
                  placement: "top",
                },
              },
              [
                _c("el-button", {
                  attrs: { size: "mini", circle: "", icon: "el-icon-refresh" },
                  on: { click: _vm.getVideoList },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.videos.length === 0
        ? _c("el-empty", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingVideo,
                expression: "loadingVideo",
              },
            ],
            attrs: { "image-size": 50 },
          })
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingVideo,
                  expression: "loadingVideo",
                },
              ],
              staticClass: "mediaBox",
            },
            _vm._l(_vm.videos, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "video-container" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flexBox",
                      on: {
                        click: function ($event) {
                          return _vm.goVideoManager(item)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-center video-cover f-shrink" },
                        [
                          _c("video", {
                            staticClass: "video-cover",
                            attrs: { src: item.url, alt: "video" },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml5 parentWidth" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: item.taskName,
                                placement: "top",
                              },
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v(_vm._s(item.taskName)),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              _vm._s(_vm.$t("videoManager.length")) +
                                "：" +
                                _vm._s(item.videoTotalLength)
                            ),
                          ]),
                          _c("dict-tag", {
                            staticClass: "time mt10",
                            attrs: {
                              options: _vm.dict.type.issue_type,
                              value: item.taskType,
                            },
                          }),
                          _c("div", { staticClass: "iconWidth" }, [
                            _c("div", { staticClass: "timeFont" }, [
                              _vm._v(_vm._s(item.createTime)),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { width: "50px" } },
                              [
                                item.status == 0
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("videoManager.statusDraft")
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("workbench.generating"),
                                      placement: "top",
                                    },
                                  },
                                  [
                                    item.status == 1
                                      ? _c("i", {
                                          staticClass: "el-icon-loading",
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t("workbench.generateFail"),
                                      placement: "top",
                                    },
                                  },
                                  [
                                    item.status == 2
                                      ? _c("i", {
                                          staticClass: "el-icon-circle-close",
                                          staticStyle: { color: "red" },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t(
                                        "workbench.generateSuccess"
                                      ),
                                      placement: "top",
                                    },
                                  },
                                  [
                                    item.status == 3
                                      ? _c("i", {
                                          staticClass: "el-icon-circle-check",
                                          staticStyle: { color: "#00A0FF" },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
      _c("CompanyInfo", {
        ref: "companyInfo",
        attrs: { visible: _vm.infoVisible, user: _vm.user },
        on: {
          "update:visible": function ($event) {
            _vm.infoVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }