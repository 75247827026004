<template>
  <div class=" home">
    <el-row :gutter="20">
      <!-- 快捷入口 -->
      <el-col :span="16">
        <!-- <div class="hello">{{ $t('workbench.welcomeMessage') }}</div>
        <div class="hello-tip">{{ $t('workbench.startVideo') }}</div> -->
        <el-row :gutter="20">
          <el-col :span="12" v-for=" (item,index) in buttons" :key="index">
            <TabItem :template="item" style="height: 100%;"/>
          </el-col>
        </el-row>
      </el-col>
      <!-- 账号统计 -->
      <el-col :span="8">
        <div class="matrix-container" v-loading="workLoading">
          <div class="flex-row-center ml20 mr20">
            <img :src="avatar" style="width: 40px;height: 40px;">
            <div class="f-grow ml10">
              <div class="work-title mb5">{{ $t('workbench.accountCount') }}：{{ workStatistics.socialCount || 0 }}</div>
              <router-link class="link-type" :to="'/account'">{{ $t('workbench.newAccount') }}</router-link>
            </div>
            <!-- <router-link class="link-type" :to="'/account'">查看数据总览</router-link> -->
          </div>
          <el-row :gutter="1" class="mt20">
            <el-col :span="6">
              <div class="flex-column-center" style="border-right: 1px solid #fff;">
                <img src="../assets/images/workbench/num_fans.png" alt="" style="width: 40px;height: 40px;">
                <span style="margin-top: 8px;">{{ $t('workbench.fanCount') }}</span>
                <span style="margin-top: 15px;">{{ workStatistics.fansCount || 0 }}</span>
                <!-- <span style="margin-top: 9px;">
                  <i :class="getIcon(workStatistics.fansTodayAddCount, workStatistics.fansYesterdayAddCount)"></i>
                  {{ getDiff(workStatistics.fansTodayAddCount, workStatistics.fansYesterdayAddCount) }}
                </span> -->
              </div>
            </el-col>
            <el-col :span="6">
              <div class="flex-column-center" style="border-right: 1px solid #fff;">
                <img src="../assets/images/workbench/num_works.png" alt="" style="width: 40px;height: 40px;">
                <span style="margin-top: 8px;">{{ $t('workbench.workCount') }}</span>
                <span style="margin-top: 15px;">{{ workStatistics.videoCount || 0 }}</span>
                <!-- <span style="margin-top: 9px;">
                  <i :class="getIcon(workStatistics.videoTodayAddCount, workStatistics.videoYesterdayAddCount)"></i>
                  {{ getDiff(workStatistics.videoTodayAddCount, workStatistics.videoYesterdayAddCount) }}
                </span> -->
              </div>
            </el-col>
            <el-col :span="6">
              <div class="flex-column-center" style="border-right: 1px solid #fff;">
                <img src="../assets/images/workbench/num_video.png" alt="" style="width: 40px;height: 40px;">
                <span style="margin-top: 8px;">{{ $t('workbench.playCount') }}</span>
                <span style="margin-top: 15px;">{{ workStatistics.viewCount || 0 }}</span>
                <!-- <span style="margin-top: 9px;">
                  <i :class="getIcon(workStatistics.viewTodayAddCount, workStatistics.viewYesterdayAddCount)"></i>
                  {{ getDiff(workStatistics.viewTodayAddCount, workStatistics.viewYesterdayAddCount) }}
                </span> -->
              </div>
            </el-col>
            <el-col :span="6">
              <div class="flex-column-center">
                <img src="../assets/images/workbench/num_comment.png" alt="" style="width: 40px;height: 40px;">
                <span style="margin-top: 8px;">{{ $t('workbench.commentCount') }}</span>
                <span style="margin-top: 15px;">{{ workStatistics.commentCount || 0 }}</span>
                <!-- <span style="margin-top: 9px;">
                  <i :class="getIcon(workStatistics.commentTodayAddCount, workStatistics.commentYesterdayAddCount)"></i>
                  {{ getDiff(workStatistics.commentTodayAddCount, workStatistics.commentYesterdayAddCount) }}
                </span> -->
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 发布任务 -->
    <div class="flex-row-center f-between mb10">
      <div>
        <span class="work-title">{{ $t('workbench.publishTask') }}</span>
        <el-radio-group v-model="socialType" class="custom-radio-group" @change="getPublishList">
          <el-radio v-for="dict in dict.type.social_platform" :key="dict.value" :label="dict.value">
            <template v-slot:default>
              <i
                class="iconfont mr5 font20"
                :class="['icon-' + dict.value + '-normal', socialType==dict.value ? dict.value : 'unbind']"
              ></i>
              {{ dict.label }}
              <!-- <span>选项文本</span> -->
            </template>
          </el-radio>
        </el-radio-group>
        <!-- <svg-icon icon-class="tiktok" class-name="card-panel-icon" />
        TikTok -->
      </div>
      <!-- <div>
        <span class="mr5">{{ $t('workbench.account') }}</span>
        <el-select v-model="account" :placeholder="$t('tip.select')" @change="getPublishList" style="background: transparent;border: none;">
          <el-option
            v-for="item in accountList"
            :key="item.id"
            :label="item.nickname"
            :value="item.id">
          </el-option>
        </el-select>
        <span class="ml5">{{ $t('workbench.fans') }}：2831</span>
        <span class="ml5">{{ $t('workbench.likes') }}：1123</span>
        <span class="ml5">{{ $t('workbench.posts') }}：36</span>
        <el-tooltip class="ml10" effect="dark" :content="$t('refresh')" placement="top">
          <el-button size="mini" circle icon="el-icon-refresh" @click="getPublishList" />
        </el-tooltip>
      </div> -->
    </div>
    <el-empty  v-if="publish.length === 0" v-loading="loading" :image-size="50"></el-empty>
    <el-row v-else v-loading="loading">
      <el-col :span="6" v-for=" item in publish" :key="item.id" >
        <div class="publish-containerContent">
          <div class="publish-container" @click="goTarget('/issueManager')">
            <div class="flex-row-center">
              <div class="point"></div>
              <span class="pulish-title ml10 f-grow">{{ item.taskName }}</span>
              <span v-if="item.status == 0">{{$t('issueManager.statusDraft')}}</span>
              <el-tooltip effect="dark" :content="$t('workbench.publishing')" placement="top">
                <i v-if="item.status == 1" class="el-icon-loading"></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('workbench.publishSuccess')" placement="top">
                <i v-if="item.status == 2" class="el-icon-circle-check" style="color: #00A0FF;"></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('workbench.publishFail')" placement="top">
                <i v-if="item.status == 3" class="el-icon-circle-close" style="color: red;"></i>
              </el-tooltip>
            </div>
            <el-tooltip effect="dark" :content="item.postContent" placement="top">
              <span class="post-content f-grow">{{ item.postContent || "--" }}</span>
            </el-tooltip>
            <div class="flex-row-center f-between time">
              <dict-tag :options="dict.type.publish_time_type" :value="item.publishTimeType" />
              <span>{{ item.publishTime }}</span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 视频创作 -->
    <div class="flex-row-center f-between mt20 mb10">
      <span class="work-title">{{ $t('workbench.videoCreation') }}</span>
      <div>
        <span class="ml5">{{ $t('workbench.videoMix') }}：{{ videoStatistics.clip }}</span>
        <span class="ml5">{{ $t('workbench.videoByText') }}：{{ videoStatistics.aiClip }}</span>
        <span class="ml5">{{ $t('workbench.AIVideo') }}：{{ videoStatistics.ai }}</span>
        <el-tooltip class="ml10" effect="dark" :content="$t('refresh')" placement="top">
          <el-button size="mini" circle icon="el-icon-refresh" @click="getVideoList" />
        </el-tooltip>
      </div>
    </div>
    <el-empty  v-if="videos.length === 0" v-loading="loadingVideo" :image-size="50"></el-empty>
    <div v-else v-loading="loadingVideo" class="mediaBox">
      <div v-for=" item in videos" :key="item.id" class="video-container">
        <div class="flexBox" @click="goVideoManager(item)">
          <div class="flex-center video-cover f-shrink" >
            <video :src="item.url" alt="video" class="video-cover" />
          </div>
          <div class="ml5 parentWidth">
            <el-tooltip class="item" effect="dark" :content="item.taskName" placement="top">
              <div class="title">{{ item.taskName }}</div>
            </el-tooltip>
            <div class="content">{{ $t('videoManager.length') }}：{{ item.videoTotalLength }}</div>
            <dict-tag class="time mt10" :options="dict.type.issue_type" :value="item.taskType" />
            <div class="iconWidth">
              <div class="timeFont">{{ item.createTime }}</div>
              <div style="width: 50px;">
                <span v-if="item.status == 0">{{$t('videoManager.statusDraft')}}</span>
                <el-tooltip effect="dark" :content="$t('workbench.generating')" placement="top">
                  <i v-if="item.status == 1" class="el-icon-loading"></i>
                </el-tooltip>
                <el-tooltip effect="dark" :content="$t('workbench.generateFail')" placement="top">
                  <i v-if="item.status == 2" class="el-icon-circle-close" style="color: red;"></i>
                </el-tooltip>
                <el-tooltip effect="dark" :content="$t('workbench.generateSuccess')" placement="top">
                  <i v-if="item.status == 3" class="el-icon-circle-check" style="color: #00A0FF;"></i>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-row v-loading="loadingVideo">
      <el-col :span="6" v-for=" item in videos" :key="item.id">
        <div class="video-container flex-row-center" @click="goTarget('/videoManager/videoManager')">
          <div class="flex-center video-cover f-shrink" >
            <video :src="item.url" alt="video" class="video-cover" />
          </div>
          <div class="ml5 parentWidth">
            <el-tooltip class="item" effect="dark" :content="item.taskName" placement="top">
              <div class="title">{{ item.taskName }}</div>
            </el-tooltip>
            <div class="content">{{ $t('videoManager.length') }}：{{ item.videoTotalLength }}</div>
            <dict-tag class="time mt10" :options="dict.type.issue_type" :value="item.taskType" />
            <div class="flex-row-center f-between iconWidth">
              <span class="time">{{ item.createTime }}</span>
              <span v-if="item.status == 0">{{$t('videoManager.statusDraft')}}</span>
              <el-tooltip effect="dark" :content="$t('workbench.generating')" placement="top">
                <i v-if="item.status == 1" class="el-icon-loading"></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('workbench.generateFail')" placement="top">
                <i v-if="item.status == 2" class="el-icon-circle-close" style="color: red;"></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('workbench.generateSuccess')" placement="top">
                <i v-if="item.status == 3" class="el-icon-circle-check" style="color: #00A0FF;"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
      </el-col>
    </el-row> -->
    <!-- <el-row :gutter="40" class="mt20">
      <el-col :span="8" >
        <div class="module-bg">
          <div class="flex-row-center f-between ">
            <span class="work-title">{{ $t('workbench.AISales') }}</span>
            <span @click="goTarget('')" style="cursor: pointer;" >{{ $t('workbench.viewMore') }}</span>
          </div>
          <div class="module-content">
            <ul class="custom-list">
              <li v-for="(item, index) in sales" :key="index" class="flex-row-center" >
                <span class="f-grow single-line-ellipsis">{{ item.content }}</span>
                <span class="f-shrink ml5">{{ $t('workbench.commentsCount1') }}{{ item.num }}{{ $t('workbench.commentsCount2') }}</span>
                <el-switch class="f-shrink ml5"
                  v-model="item.open"
                  active-color="#0052D9">
                </el-switch>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
      <el-col :span="8" >
        <div class="module-bg">
          <div class="flex-row-center f-between">
            <span class="work-title">{{ $t('workbench.announcement') }}</span>
            <span @click="goTarget('')" style="cursor: pointer;">{{ $t('workbench.viewMore') }}</span>
          </div>
          <div class="module-content ">
            <div v-for="(item, index) in notice" :key="index" class="flex-row-center" style="padding: 10px">
              <span class="f-shrink" :class="'notice-'+ item.type" >{{ getNoticeType(item.type) }}</span>
              <span class="f-grow ml5 single-line-ellipsis">{{ item.content }}</span>
              <span class="f-shrink ml5">{{ item.time }}</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="module-bg">
          <span class="work-title">{{ $t('workbench.helpDoc') }}</span>
          <div class="module-content">
            <el-row>
              <el-col :span="12" v-for="(item, index) in help" :key="index" >
                <div class="flex-column-center">
                  <img :src="item.url" alt="" class="help-img">
                  <span class="help-title">{{ item.name }}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row> -->
    <CompanyInfo
      ref="companyInfo"
      :visible.sync="infoVisible"
      :user="user"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabItem from './home/TabItem.vue';
import { getQuota, myMerchant } from '../api/system/merchant';
import { getAccount, userStatistics, listAccount } from '../api/account/tiktok';
import { listPublish } from '../api/ai/publish';
import { getIndexStatistics, listVideoTask } from '../api/ai/videoTask';
import CompanyInfo from './home/CompanyInfo.vue';

export default {
  // name: "Index",
  dicts: [
    'publish_time_type', 'issue_type', 'social_platform'
  ],
  components: {
    TabItem,
    CompanyInfo
  },
  computed: {
    ...mapGetters([
      'avatar',
    ]),
  },
  data() {
    return {
      quota: {},
      workStatistics: {},
      workLoading: false,
      buttons:[
        {
          name:this.$t('workbench.accountManagement'),
          path:'/account',
          quota:'',
          use:'',
          des:this.$t('workbench.accountManagementDes'),
          title:this.$t('workbench.accountManagementTitle'),
          hover:false,
          background: "tab_account.png",
          icon: "tab_account_icon.png",
        },
        {
          name:this.$t('workbench.materia'),
          path:'/material',
          quota:'',
          use:'',
          des:this.$t('workbench.materiaDes'),
          title:this.$t('workbench.materiaTitle'),
          hover:false,
          background: "tab_material.png",
          icon: "tab_material_icon.png",
        },
        {
          name:this.$t('workbench.modelTraining'),
          path:'',
          quota:'',
          use:'',
          des:this.$t('workbench.modeLDes'),
          title:this.$t('workbench.modeLDes'),
          hover:false,
          background: "tab_training.png",
          icon: "tab_training_icon.png",
        },
        {
          name:this.$t('workbench.videoProduction'),
          path:'/videoManager/videoManager',
          quota:'',
          use:'',
          des: this.$t('workbench.videoProductionDes'),
          title: this.$t('workbench.videoProductionTitle'),
          hover:false,
          background: "tab_video_clips.png",
          icon: "tab_video_clips_icon.png",
        },
        {
          name:'AI',
          path:'/videoManager/make_video',
          quota:'',
          use:'',
          des:this.$t('workbench.AIDes'),
          title:this.$t('workbench.AITitle'),
          hover:false,
          background: "tab_ai_clips.png",
          icon: "tab_ai_clips_icon.png",
        },
        {
          name:this.$t('workbench.releaseManagement'),
          path:'/issueManager',
          quota:'',
          use:'',
          des:this.$t('workbench.releaseManagementDes'),
          title:this.$t('workbench.releaseManagementTitle'),
          hover:false,
          background: "tab_release.png",
          icon: "tab_release_icon.png",
        },
      ],
      accountList: [],//账号列表
      account: '',//当前账号
      loading: false,//发布的loading
      // loadingPublishNum: false,//发布数量的loading
      // publishNum:{}, // 发布对应的数量
      publish: [],
      loadingVideo: false,//视频的loading
      videoStatistics: {},//视频统计
      videos: [],
      sales: [{
        content: '专一感源于整齐划一',
        num: 1000,
        open: true,
      },
      {
        content: '好设计是诚实的',
        num: 1300,
        open: false,
      },
      {
        content: '好设计是尽可能的无设计',
        num: 3400,
        open: true,
      },
      {
        content: '割草机视频',
        num: 1234,
        open: true,
      },
      {
        content: '塑像本来我只是把不要的部分去掉',
        num: 1007,
        open: true,
      },
      {
        content: '好设计是有创意的',
        num: 1505,
        open: true,
      }],
      notice: [{
        type: '1',
        content: '新用户优惠活动开启啦！',
        time: '2023-08-01',
      },
      {
        type: '2',
        content: '抖音账号审核不通过',
        time: '2023-07-01',
      },
      {
        type: '3',
        content: '视频生成失败',
        time: '2023-03-01',
      },
      {
        type: '1',
        content: '新用户优惠活动开启啦！',
        time: '2022-08-01',
      },
      {
        type: '2',
        content: '抖音账号审核不通过',
        time: '2022-07-01',
      },
      {
        type: '3',
        content: '视频生成失败',
        time: '2022-03-01',
      }],
      help: [{
        url: require("@/assets/images/workbench/help_product.png"),
        name: this.$t('workbench.productSummary'),
      },{
        url: require("@/assets/images/workbench/help_usage.png"),
        name: this.$t('workbench.userGuide'),
      },{
        url: require("@/assets/images/workbench/help_package.png"),
        name: this.$t('workbench.planDescription'),
      },{
        url: require("@/assets/images/workbench/help_product_info.png"),
        name: this.$t('workbench.productNews'),
      }],
      user: {},
      infoVisible: false,
      socialType: 'tiktok',
    };
  },
  created(){
    this.getQuota()
    this.getWorkStatistics()
    this.getAccountList()
    this.getPublishList()
    this.getVideoList()
    this.getUser()
  },
  methods: {
    goVideoManager(item){
      if(item.taskType == 3){
        this.goTarget('/videoManager/aiCrosscutting')
      } else {
        this.goTarget('/videoManager/videoManager')
      }
    },
    getUser() {
      myMerchant().then((response) => {
        this.user = response.data || {};
        if(!this.user || (!this.user.industryCategory && !this.user.companyName && !this.user.brand && !this.user.product)){
          this.$confirm(this.$t('tip.infoPrompt'), this.$t('tip.tip'), {
            confirmButtonText: this.$t('tip.completeNow'),
            cancelButtonText: this.$t('tip.later'),
            // showCancelButton: false,
            type: "warning",
            showClose: false,
            distinguishCancelAndClose: true,    // 重要，设置为true才会把右上角X和取消区分开来
            closeOnClickModal: false
          }).then(() => {
            this.infoVisible = true
            this.$nextTick().then((rs) => {
              if(this.user && this.user.industryCategory){
                this.user.industryCategorys = this.user.industryCategory.split(',')
              }
              this.$refs.companyInfo.init(this.user)
            });
          }).catch(() => {
          });
        }
      });
    },
    // 计算图标的 class，根据 today 和 yesterday 的差值返回不同图标
    getIcon(todayAddCount, yesterdayAddCount) {
      const diff = todayAddCount || 0 - yesterdayAddCount || 0;
      return diff > 0 ? 'icon-up' : diff < 0 ? 'icon-down' : 'icon-neutral';
    },
    // 计算差值并取正数
    getDiff(todayAddCount, yesterdayAddCount) {
      return Math.abs(todayAddCount || 0 - yesterdayAddCount || 0);
    },
    getWorkStatistics(){
      this.workLoading = true
      userStatistics().then(response => {
        this.workStatistics = response.data || {}
      }).finally(() => {
        this.workLoading = false
      })
    },
    goTarget(href) {
      if (href) {
        this.$router.push({path: href})
      } else {
        this.$modal.alert(this.$t('workbench.notOpen'))
      }
    },
    //获取配额
    getQuota(){
      getQuota().then(response => {
        this.quota = response.data || {}
        this.buttons.forEach(button => {
          switch (button.path) {
            case '/account':
              button.quota = this.quota.socialCount
              button.use = this.quota.useSocialCount
              break;
            case '/material':
              button.quota = this.quota.fileSize
              button.use = this.quota.useFileSize
              break;
            case '/videoManager/videoManager':
              button.quota = this.quota.videoLength
              button.use = this.quota.useVideoLength
              break;
            case '/issueManager':
              button.quota = this.quota.publishCount
              button.use = this.quota.usePublishCount
              break;
            default:
              button.quota = '';
              button.use = '';
          }
        });
      });
    },
    /** 账号列表 */
    getAccountList() {
      // listAccount().then(response => {
      //   this.accountList = response.rows;
      //   if(this.accountList.length>0){
      //     this.account = this.accountList[0].id
      //     this.getPublishList()
      //   }
      // })
    },
    getPublishList() {
      // if(!this.account){
      //   this.getAccountList()
      //   return
      // }
      this.loading = true
      listPublish({
        pageNum: 1,
        pageSize: 4,
        socialType: this.socialType,
        // socialId: this.account,
      }).then(response => {
        this.publish = response.rows;
      }).finally(() => {
        this.loading = false
      })
      // this.loadingPublishNum = true
      // getAccount(this.account).then(response => {
      //   this.publishNum = response.data || {}
      // }).finally(() => {
      //   this.loadingPublishNum = false
      // })
    },
    getVideoList() {
      this.getIndexStatistics()
      this.loadingVideo = true
      listVideoTask({
        pageNum: 1,
        pageSize: 4,
      }).then((response) => {
        this.videos = response.rows;
      }).finally(() => {
        this.loadingVideo = false
      })
    },
    getIndexStatistics(){
      getIndexStatistics().then(response => {
        this.videoStatistics = response.data || {}
      })
    },
    getNoticeType(type){
      if(type == '1'){
        return this.$t('workbench.events')
      }else if(type == '2'){
        return this.$t('workbench.messages')
      }else if(type == '3'){
        return this.$t('workbench.notifications')
      }
    },
  },
};
</script>

<style scoped lang="scss">
.publish-containerContent {
  height: 150px;
}
.custom-radio-group ::v-deep.el-radio__input {
    display: none; /* 隐藏原点 */
}
.custom-radio-group .el-radio__label {
    display: flex; /* 使内容水平排列 */
    align-items: center; /* 垂直居中 */
}
.el-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.el-col {
  align-items: stretch; /* 让子元素在垂直方向上拉伸，保持高度一致 */
  margin-bottom: 10px;
}
.matrix-container {
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 20px 0;
  img {
    width: 50px;
    height: 50px;
  }
}
.home {
  // background: transparent;
  padding: 20px;
  background-color: #F2F6F8;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;
  background-image: url("../assets/images/workbench/bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.hello {
  font-family: PingFang-SC-Bold;
  font-size: 44px;
  color: #152133;
  letter-spacing: 1.29px;
  font-weight: 700;
}
.hello-tip {
  font-family: PingFang-SC-Regular;
  font-size: 20px;
  color: #152133;
  letter-spacing: 0.59px;
  font-weight: 400;
}
.work{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #152133;
  letter-spacing: 0;
  font-weight: 400;
}
.work-title{
  font-family: PingFang-SC-Heavy;
  font-size: 18px;
  color: #152133;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 400;
  margin-right: 32px;
}
.el-input__inner{
  background: transparent;
  border: none;
}
.post-content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-clamp: 2;
  margin: 13px 0 27px 12px;
}
.video-cover {
  width: 100px;
  height: 100px;
}
.module-bg {
  height: 100%;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 16px 12px 0 0;
  // margin-right: 10px;
  background-image: url("../assets/images/workbench/bg_small.png");
  background-size: cover ;// 这也能防止图片重复出现
  background-position: center; /* 保证图片居中 */
}
.module-content {
  background: #fff;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
.custom-list {
  list-style: none; /* 去掉默认的项目符号 */
  padding-left: 20px; /* 添加左边距使符号显示 */
}

.custom-list li {
  position: relative;
  padding: 10px; /* 根据需要调整 */
}

.custom-list li::before {
  content: '•'; /* 使用圆点作为符号 */
  position: absolute;
  left: 0;
  font-size: 16px; /* 修改点的大小 */
  color: #016FEE; /* 修改点的颜色 */
}

.notice-1 {
  background: #FFF7E9;
  border-radius: 2px;
  font-size: 12px;
  color: #FF8200;
  line-height: 20px;
  padding: 1px 8px;
}
.notice-2 {
  background: #CCF6E4;
  border-radius: 2px;
  color: #03864F;
  line-height: 20px;
  padding: 1px 8px;
}
.notice-3 {
  background: #D5E5FA;
  border-radius: 2px;
  color: #006FEE;
  line-height: 20px;
  padding: 1px 8px;
}
.help-img{
  width: 38px;
  height: 48px;
  margin-top: 23px;
}
.help-title{
  font-size: 14px;
  color: #152133;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 16px;
}
.publish-container{
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-right: 16px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .point{
    width: 8px;
    height: 8px;
    background-color: #006FEE;
    border-radius: 8px;
  }
  .pulish-title{
    font-size: 14px;
    color: rgba(0,0,0,0.90);
    letter-spacing: 1px;
    line-height: 22px;
    font-weight: 500;
  }
  .content {
    font-size: 12px;
    color: rgba(0,0,0,0.90);
    text-align: left;
    line-height: 14px;
    font-weight: 400;
  }
  .time {
    font-size: 12px;
    color: rgba(0,0,0,0.26);
    line-height: 20px;
    font-weight: 400;
  }
}
.video-container{
  width: 24%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  // margin-right: 16px;
  padding: 8px 16px 8px 10px;
  cursor: pointer;
  overflow: hidden;

  .flexBox {
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
  .parentWidth {
    width: 100%;
  }

  .iconWidth {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .timeFont {
      width: 150px;
      font-size: 12px;
      color: rgba(0,0,0,0.26);
      line-height: 20px;
      font-weight: 400;
    }
  }

  .title{
    width: 90%;
    font-size: 14px;
    color: rgba(0,0,0,0.90);
    letter-spacing: 1px;
    line-height: 22px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .content {
    font-size: 12px;
    color: rgba(0,0,0,0.90);
    text-align: left;
    line-height: 14px;
    font-weight: 400;
  }
  .time {
    font-size: 12px;
    color: rgba(0,0,0,0.26);
    line-height: 20px;
    font-weight: 400;
  }
}
.mediaBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .mediaBox {
    width: 24.5%;

  }
}
</style>

