var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "list-item flex-row-center",
      style: { zIndex: _vm.item.hover ? 10 : "auto" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleSelection($event)
        },
        mouseover: _vm.mouseoverItem,
        mouseleave: _vm.mouseleaveItem,
      },
    },
    [
      _c("div", { staticClass: "video-container f-shrink" }, [
        _c("video", {
          staticClass: "video-list-cover",
          attrs: { src: _vm.item.videoUrl, alt: "video" },
        }),
        _vm.item.videoLength && _vm.item.videoLength > 0
          ? _c("span", { staticClass: "video-list-duration" }, [
              _vm._v(_vm._s(_vm.secondsFormatTime(_vm.item.videoLength))),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "f-grow video-right" }, [
        _c(
          "div",
          {
            staticClass: "video-text line-2",
            class:
              _vm.item.hover || _vm.item.selected ? "video-text-hover" : "",
          },
          [_vm._v(" " + _vm._s(_vm.item.postContent) + " ")]
        ),
        _c("div", { staticClass: "nums flex-row-center" }, [
          _c("i", { staticClass: "iconfont icon-dianzan" }),
          _vm._v(" " + _vm._s(_vm.item.likes) + " "),
          _c("i", { staticClass: "iconfont icon-pinglun ml16" }),
          _vm._v(" " + _vm._s(_vm.item.comments) + " "),
          _c("i", { staticClass: "iconfont icon-zhuanfa ml16" }),
          _vm._v(" " + _vm._s(_vm.item.shares) + " "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }