<template>
  <div
    class="faddish-detail"
    v-loading="loading"
  >
    <div class="flex-column detail-left">
      <video
        controls
        :src="detail.videoUrl"
        class="detail-video"
        ref="videoPlayer"
      ></video>
      <div class="detail-text">{{ detail.postContent }}</div>
      <div class="detail-topic flex-row f-wrap">
        <div
          class="mr5 mb5"
          v-for="(item, index) in detail.labels"
          :key="index"
        >#{{ item }}</div>
      </div>
    </div>
    <div class="flex-column detail-right">
      <span class="detail-title mt16">{{ $t('faddish.author') }}</span>
      <div class=" mt16 flex-row-center">
        <el-avatar
          :size="56"
          :src="detail.userAvatar"
        ></el-avatar>
        <div class="flex-column ml16">
          <span class="
          detail-title">Caleb Webster</span>
          <span
            class="detail-normal"
            style="margin-top: 14px;"
          >{{ $t('faddish.fans') }}：{{ detail.fansCount }}</span>
        </div>
      </div>
      <div
        class="detail-title mt32"
        v-if="detail.labels && detail.labels.length > 0"
      >{{ $t('faddish.tags') }}</div>
      <div class="detail-labels">
        <el-tag
          v-for="(tag, index) in detail.labels"
          class="label-normal"
          :key="index"
          :class="getLabelClass(index)"
        >
          #{{ tag }}
        </el-tag>
      </div>
      <div class="detail-title mt32">{{ $t('faddish.basicInfo') }}</div>
      <div class="detail-statistics flex-row-center mt16">
        <!-- <div class="flex-column-center">
          <div class="detail-num">{{ detail.views || 0 }}</div>
          <div class="detail-num-name">播放量</div>
        </div> -->
        <div class="flex-column-center">
          <div class="detail-num">{{ detail.likes || 0 }}</div>
          <div class="detail-num-name">{{ $t('faddish.likes') }}</div>
        </div>
        <div class="flex-column-center">
          <div class="detail-num">{{ detail.shares || 0 }}</div>
          <div class="detail-num-name">{{ $t('faddish.shares') }}</div>
        </div>
        <div class="flex-column-center">
          <div class="detail-num">{{ detail.comments || 0 }}</div>
          <div class="detail-num-name">{{ $t('faddish.comments') }}</div>
        </div>
      </div>
      <div class="detail-time detail-gray">
        {{ $t('faddish.publishTime') }}：{{ detail.publishTime }}
      </div>
      <div class="detail-interaction detail-gray">
        {{ $t('faddish.interactions') }}：
        <i class="iconfont icon-dianzan"></i> {{ detail.likes }}
        <i class="iconfont icon-pinglun ml16"></i> {{ detail.comments }}
        <i class="iconfont icon-zhuanfa ml16"></i> {{ detail.shares }}
        <i class="iconfont icon-quxiaoshoucang ml16"></i> {{ detail.collects }}
      </div>
      <div class="detail-title mt32">{{ $t('faddish.textBreakdown') }}</div>
      <div class="detail-content flex-column mt16">
        <div
          class="flex-row mb16"
          v-for="(item, index) in detail.videoContentList"
          :key="index"
        >
          <div class="icon-scene flex-center f-shrink">
            <i class="iconfont icon-shengyin"></i>
          </div>
          <span class="scene-num f-shrink">{{ $t('faddish.scenes') }}{{ index + 1 }}</span>
          <span class="scene-content">{{ item }}</span>
        </div>
        <div
          class="self-end fix-btn"
          @click="$emit('make-video', detail)"
        >
          <i class="iconfont icon-chaijie"></i>
          {{ $t('faddish.startMixing') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPopularDetail } from "../../api/ai/faddish";

export default {
  name: "FaddishDetail",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: "",
      loading: false,
      detail: {},
    };
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.detail = val;
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  created() {},
  methods: {
    init(id) {
      this.id = id;
      this.getDetail();
    },
    // 外面抽屉关闭时，这个也需要关
    pauseVideo() {
      const video = this.$refs.videoPlayer;
      video.pause();
    },
    getDetail() {
      this.loading = true;
      getPopularDetail(this.id)
        .then((res) => {
          this.detail = { ...this.detail, ...res.data };
          if (this.detail.postContent) {
            this.detail.postContent = this.detail.postContent
              .split("#")[0]
              .trim();
          }
          if (this.detail.tags) {
            this.detail.labels = JSON.parse(this.detail.tags);
          } else {
            this.detail.labels = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 根据索引返回对应的样式类名
    getLabelClass(index) {
      const classes = ["label-1", "label-2", "label-3"];
      return classes[index % classes.length];
    },
  },
};
</script>
<style lang="scss" scoped>
.faddish-detail {
  padding: 24px;
  // height: 100%;
  display: flex;
  flex-direction: row;
  .detail-left {
    width: 400px;
    .detail-video {
      width: 100%;
      height: 711px;
      background: black;
      border-radius: 14px;
    }
    .detail-text {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 28px;
      text-align: left;
      font-style: normal;
      margin-top: 24px;
    }
    .detail-topic {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 18px;
      color: #0156ff;
      line-height: 28px;
      text-align: left;
      font-style: normal;
      margin-top: 16px;
    }
  }
  .detail-right {
    margin-left: 32px;
    flex-grow: 1;
    .detail-title {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }
    .detail-normal {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
    .detail-gray {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      line-height: 18px;
      text-align: left;
      font-style: normal;
    }
  }
}
.detail-labels {
  margin-top: 19px;
}
.label-normal {
  border-radius: 8px;
  padding: 1px 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  margin-right: 8px;
  margin-bottom: 5px;

  .label-1 {
    background: #e8f8f2;
    color: #00ac6c;
  }
  .label-2 {
    background: #fef3e6;
    color: #ee7a01;
  }
  .label-3 {
    background: #d5ffff;
    color: #00919f;
  }
}

.detail-statistics {
  width: 100%;
  background: #f0f3fa;
  border-radius: 12px 12px 0px 0px;
  padding: 16px 0;
  justify-content: space-around;
  .detail-num {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
  .detail-num-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    line-height: 18px;
    text-align: center;
    font-style: normal;
    margin-top: 8px;
  }
}
.detail-time {
  width: 100%;
  background: #f0f3fa;
  padding: 11px 24px;
  margin-top: 2px;
}
.detail-interaction {
  width: 100%;
  background: #f0f3fa;
  border-radius: 0px 0px 12px 12px;
  padding: 11px 24px;
  margin-top: 2px;
  .iconfont {
    color: #387dee;
  }
}
.detail-content {
  width: 100%;
  background: #f0f3fa;
  border-radius: 12px;
  padding: 24px;
  .icon-scene {
    width: 18px;
    height: 18px;
    background: #909499;
    border-radius: 50%;
    font-size: 14px;
    color: white;
    margin-right: 8px;
  }
  .scene-num {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 12px;
    color: #9798a1;
    line-height: 16px;
    text-align: center;
    font-style: normal;
    margin-right: 8px;
  }
  .scene-content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #9798a1;
    line-height: 16px;
    text-align: left;
    font-style: normal;
  }
  .fix-btn {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #0156ff;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    cursor: pointer;
  }
}
</style>
