<template>
  <div class="not-open">
    <h1>{{ $t('workbench.notOpen') }}</h1>
  </div>
</template>

<script>
export default {
  name: 'NotOpen'
};
</script>

<style scoped>
.not-open {
  text-align: center;
  margin-top: 50px;
  font-size: 20px;
  color: #999;
}
</style>
