var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "publicDialog mixDialog",
      attrs: {
        visible: _vm.localVisible,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.$t("faddish.copyAnalysis"))),
          ]),
          _c("i", { staticClass: "el-icon-close", on: { click: _vm.close } }),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "accountHeight flex-column",
        },
        _vm._l(_vm.detail.videoContentList, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "flex-column mix-scene" },
            [
              _c("span", { staticClass: "mix-scene-num f-shrink" }, [
                _vm._v(
                  _vm._s(_vm.$t("faddish.scenes")) +
                    _vm._s(index + 1) +
                    _vm._s(_vm.$t("faddish.commentary")) +
                    "："
                ),
              ]),
              _c("span", { staticClass: "mix-scene-content" }, [
                _vm._v(_vm._s(item)),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "flex-center mt20" },
        [
          _c(
            "el-button",
            {
              staticClass: "cancelBtn btnWidth120",
              on: { click: _vm.handleCancel },
            },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "submitBtn btnWidth120",
              attrs: { disabled: _vm.loading, type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("faddish.createVideo")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }