<!-- 账号协同 -->
<template>
  <el-dialog
    class="publicDialog"
    width="50%"
    :visible.sync="localVisible"
    :title="$t('register.completeInformation')"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ $t('register.completeInformation') }}</div>
        <i
          class="el-icon-close"
          @click="handleCancel"
        ></i>
      </div>
    </div>
    <el-form
      ref="registerForm"
      :model="registerForm"
      :rules="registerRules"
      label-position="top"
      class="register-form"
    >
      <el-form-item
        :label="$t('register.companyName')"
        prop="companyName"
      >
        <el-input
          v-model="registerForm.companyName"
          type="text"
          autocomplete="off"
          maxlength="50"
          :placeholder="$t('tip.input')"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        :label="$t('register.industry')"
        prop="industryCategorys"
        style="margin-bottom:20px"
      >
        <el-cascader
          style="width: 100%;"
          :show-all-levels="false"
          :props="props"
          v-model="registerForm.industryCategorys"
          :options="industryCategoryOptions"
        ></el-cascader>
        <!-- @change="handleChange" -->
      </el-form-item>
      <el-form-item
        :label="$t('register.brand')"
        prop="brand"
      >
        <el-input
          v-model="registerForm.brand"
          type="text"
          autocomplete="off"
          maxlength="50"
          :placeholder="$t('register.brandTip')"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        :label="$t('register.product')"
        prop="product"
      >
        <el-input
          v-model="registerForm.product"
          type="text"
          autocomplete="off"
          maxlength="50"
          :placeholder="$t('register.productTip')"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="flex-center">
      <el-button
        class="cancelBtn btnWidth120"
        @click="handleCancel"
      >{{ $t('skip') }}</el-button>
      <el-button
        class="submitBtn btnWidth120"
        @click="handleConfirm"
        :loading="loading"
      >{{ $t('submit') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { industryCategoryTreeSelect } from "../../api/base/resource";
import { updateUserProfile } from "../../api/system/user";
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      props: {
        value: "name",
        label: "name",
        emitPath: false,
        multiple: true,
      },
      loading: false,
      localVisible: this.visible,
      registerForm: {
        companyName: "",
        industryCategorys: [],
        industryCategory: "",
        brand: "",
        product: "",
        // ...this.user
      },
      registerRules: {
        // companyName: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterCompanyName')}],
        // industryCategorys: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.selectIndustry')}],
        // brand: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterBrandName')}],
        // product: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterProduct')}],
      },
      industryCategoryOptions: undefined, // 行业分类
      maxSelection: 10, // 最大选择数量
    };
  },
  created() {},
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
  },
  methods: {
    init(user) {
      // this.registerForm = {
      //   ...this.registerForm,
      //   ...user
      // }
      this.getIndustryCategoryOptionsTree();
    },
    /** 所属行业下拉树结构 */
    getIndustryCategoryOptionsTree() {
      industryCategoryTreeSelect().then((response) => {
        this.industryCategoryOptions = response.data;
      });
    },
    handleChange(value) {
      if (value.length > this.maxSelection) {
        // 如果选中的数量超过了最大限制，移除最后选中的项
        this.$modal.msgWarning(
          `${this.$t("register.tipBrandMax1")}${this.maxSelection}${this.$t(
            "register.tipBrandMax2"
          )}`
        );
        value.pop(); // 移除多余的选项
      }
      this.selectedValues = value; // 更新选中的值
    },
    handleBeforeClose(done) {
      this.close();
      done();
    },
    handleCancel() {
      this.close();
    },
    handleConfirm() {
      if (
        !this.registerForm.companyName &&
        this.registerForm.industryCategorys.length == 0 &&
        !this.registerForm.brand &&
        !this.registerForm.product
      ) {
        this.$modal.msgError(this.$t("register.tipAtLeast"));
        return;
      }
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.registerForm.industryCategory =
            this.registerForm.industryCategorys.join(",");
          updateUserProfile(this.registerForm)
            .then((response) => {
              this.$modal.msgSuccess(this.$t("tip.submit"));
              this.close();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    close() {
      this.localVisible = false;
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style scoped>
.template-more-item {
  width: 23%;
  margin: 1%;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
