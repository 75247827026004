var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("div", { staticClass: "flex-row" }, [
        _c(
          "div",
          { staticClass: "home-left" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 16 } },
              _vm._l(_vm.buttons, function (item, index) {
                return _c(
                  "el-col",
                  { key: index, attrs: { span: 8 } },
                  [_c("TabItem", { attrs: { template: item } })],
                  1
                )
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "task-bg", staticStyle: { height: "202px" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-row-center f-between",
                    staticStyle: { "min-height": "54px" },
                  },
                  [
                    _c("div", { staticClass: "flex-row-center" }, [
                      _c("div", { staticClass: "blue-line" }),
                      _c("span", { staticClass: "home-title mr20" }, [
                        _vm._v(_vm._s(_vm.$t("workbench.aiWorkshop"))),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "workshop-box" },
                  _vm._l(_vm.aiWorkshopList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: ["workshop-item", "workshop-item" + (index + 1)],
                        on: {
                          click: function ($event) {
                            return _vm.goTarget(item.path)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "workshop-icon-box" }, [
                          _c(
                            "div",
                            {
                              class: [
                                "workshop-icon",
                                "workshop-icon" + (index + 1),
                              ],
                            },
                            [_c("i", { class: ["iconfont", item.icon] })]
                          ),
                          _c("div", { staticClass: "open-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("workbench.workshopEnter")) +
                                " "
                            ),
                            _c("i", {
                              staticClass: "iconfont icon-xiangyoujiantou",
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "workshop-title" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c("div", { staticClass: "workshop-content" }, [
                          _vm._v(_vm._s(item.content)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "home-right" }, [
          false
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.workLoading,
                      expression: "workLoading",
                    },
                  ],
                  staticClass: "matrix-container mb10",
                },
                [
                  _c("div", { staticClass: "flex-row-center" }, [
                    _c("i", {
                      staticClass: "iconfont icon-zhanghaoguanlibeifen mr5",
                      staticStyle: { color: "#0156FF", "font-size": "36px" },
                    }),
                    _c(
                      "div",
                      { staticClass: "f-grow ml10" },
                      [
                        _c("div", { staticClass: "work-title mb5" }, [
                          _vm._v(
                            _vm._s(_vm.$t("workbench.accountCount")) +
                              "：" +
                              _vm._s(_vm.workStatistics.socialCount || 0)
                          ),
                        ]),
                        _c(
                          "router-link",
                          {
                            staticClass: "link-type",
                            attrs: { to: "/account" },
                          },
                          [_vm._v(_vm._s(_vm.$t("workbench.newAccount")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "69px" },
                      attrs: { gutter: 1 },
                    },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex-column-center",
                            staticStyle: {
                              "border-right": "1px solid #F6F6F6",
                            },
                          },
                          [
                            _c("span", { staticClass: "fans-title" }, [
                              _vm._v(_vm._s(_vm.$t("workbench.fanCount"))),
                            ]),
                            _c("span", { staticClass: "fans-num" }, [
                              _vm._v(_vm._s(_vm.workStatistics.fansCount || 0)),
                            ]),
                          ]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex-column-center",
                            staticStyle: {
                              "border-right": "1px solid #F6F6F6",
                            },
                          },
                          [
                            _c("span", { staticClass: "fans-title" }, [
                              _vm._v(_vm._s(_vm.$t("workbench.workCount"))),
                            ]),
                            _c("span", { staticClass: "fans-num" }, [
                              _vm._v(
                                _vm._s(_vm.workStatistics.videoCount || 0)
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex-column-center",
                            staticStyle: {
                              "border-right": "1px solid #F6F6F6",
                            },
                          },
                          [
                            _c("span", { staticClass: "fans-title" }, [
                              _vm._v(_vm._s(_vm.$t("workbench.playCount"))),
                            ]),
                            _c("span", { staticClass: "fans-num" }, [
                              _vm._v(_vm._s(_vm.workStatistics.viewCount || 0)),
                            ]),
                          ]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "flex-column-center" }, [
                          _c("span", { staticClass: "fans-title" }, [
                            _vm._v(_vm._s(_vm.$t("workbench.commentCount"))),
                          ]),
                          _c("span", { staticClass: "fans-num" }, [
                            _vm._v(
                              _vm._s(_vm.workStatistics.commentCount || 0)
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "task-bg-border" }, [
            _c(
              "div",
              {
                staticClass: "flex-row-center ml16",
                staticStyle: { "min-height": "54px" },
              },
              [
                _c("div", { staticClass: "blue-line" }),
                _c("span", { staticClass: "home-title mr20" }, [
                  _vm._v(_vm._s(_vm.$t("workbench.communityList"))),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "dynamic-scroll-container" },
              [_c("vueScrolling", { attrs: { data: _vm.communityList } })],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "task-bg mt16", staticStyle: { height: "212px" } },
        [
          _c(
            "div",
            {
              staticClass: "flex-row-center f-between",
              staticStyle: { "min-height": "54px" },
            },
            [
              _c("div", { staticClass: "flex-row-center" }, [
                _c("div", { staticClass: "blue-line" }),
                _c("span", { staticClass: "home-title mr20" }, [
                  _vm._v(_vm._s(_vm.$t("workbench.videoCreation"))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "home-title" },
                [
                  _c("span", { staticClass: "ml5" }, [
                    _vm._v(
                      _vm._s(_vm.$t("workbench.videoMix")) +
                        "：" +
                        _vm._s(_vm.videoStatistics.clip)
                    ),
                  ]),
                  _c("span", { staticClass: "ml5" }, [
                    _vm._v(
                      _vm._s(_vm.$t("workbench.videoByText")) +
                        "：" +
                        _vm._s(_vm.videoStatistics.aiClip)
                    ),
                  ]),
                  _c("span", { staticClass: "ml5" }, [
                    _vm._v(
                      _vm._s(_vm.$t("workbench.AIVideo")) +
                        "：" +
                        _vm._s(_vm.videoStatistics.ai)
                    ),
                  ]),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "ml10",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t("refresh"),
                        placement: "top",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          circle: "",
                          icon: "el-icon-refresh",
                        },
                        on: { click: _vm.getVideoList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.videos.length === 0
            ? _c("el-empty", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingVideo,
                    expression: "loadingVideo",
                  },
                ],
                attrs: { "image-size": 40 },
              })
            : _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingVideo,
                      expression: "loadingVideo",
                    },
                  ],
                  attrs: { gutter: 16 },
                },
                _vm._l(_vm.videos, function (item) {
                  return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "video-container",
                        on: {
                          click: function ($event) {
                            return _vm.goVideoManager(item)
                          },
                        },
                      },
                      [
                        _c("video", {
                          staticClass: "video-cover f-shrink",
                          attrs: { src: item.url, alt: "video" },
                        }),
                        _c(
                          "div",
                          { staticClass: "video-right" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: item.taskName,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "video-title single-line-ellipsis",
                                  },
                                  [_vm._v(_vm._s(item.taskName))]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "video-content" }, [
                              _vm._v(
                                _vm._s(_vm.$t("videoManager.length")) +
                                  "：" +
                                  _vm._s(item.videoTotalLength)
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "flex-row-center f-between",
                                staticStyle: { "margin-top": "22px" },
                              },
                              [
                                _c("dict-tag", {
                                  staticClass: "time",
                                  attrs: {
                                    options: _vm.dict.type.issue_type,
                                    value: item.taskType,
                                  },
                                }),
                                _c("div", { staticClass: "time" }, [
                                  _vm._v(_vm._s(item.createTime)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                }),
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "task-bg mt16", staticStyle: { height: "204px" } },
        [
          _c(
            "div",
            {
              staticClass: "flex-row-center",
              staticStyle: { "min-height": "54px" },
            },
            [
              _c("div", { staticClass: "blue-line" }),
              _c("span", { staticClass: "home-title mr20" }, [
                _vm._v(_vm._s(_vm.$t("workbench.publishTask"))),
              ]),
            ]
          ),
          _vm.publish.length === 0
            ? _c("el-empty", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { "image-size": 40 },
              })
            : _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { gutter: 16 },
                },
                _vm._l(_vm.publish, function (item) {
                  return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "publish-containerContent",
                        on: {
                          click: function ($event) {
                            return _vm.goTarget("/issueManager")
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-row" },
                          [
                            _c("div", { staticClass: "point f-shrink" }),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: item.postContent,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "post-content line-2 f-grow" },
                                  [_vm._v(_vm._s(item.postContent || "--"))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex-row-center f-end publish-status",
                          },
                          [
                            item.status == 0
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("issueManager.statusDraft"))
                                  ),
                                ])
                              : _vm._e(),
                            item.status == 1
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#ED7B2F" } },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-dacha1",
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("issueManager.publishing"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            item.status == 2
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#00A870" } },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-a-dacha3",
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("issueManager.published"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            item.status == 3
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#E34D59" } },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-a-dacha2",
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("issueManager.failed"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "flex-row-center f-between time" },
                          [
                            _c(
                              "div",
                              { staticClass: "icon-gray-bg flex-center" },
                              [
                                item.socialType
                                  ? _c("i", {
                                      staticClass: "iconfont",
                                      class: [
                                        "icon-" + item.socialType + "-normal",
                                        item.socialType,
                                      ],
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c("div", [_vm._v(_vm._s(item.publishTime))]),
                          ]
                        ),
                      ]
                    ),
                  ])
                }),
                1
              ),
        ],
        1
      ),
      _vm.noticeList && _vm.noticeList.length > 0
        ? _c(
            "div",
            { staticClass: "notice-bg-border" },
            _vm._l(_vm.noticeList, function (item, index) {
              return _c(
                "div",
                { key: item.url, staticClass: "notice flex-row-center" },
                [
                  index < 3
                    ? _c("i", { staticClass: "iconfont icon-redu" })
                    : _c("div", { staticClass: "point-blue f-shrink" }),
                  _c(
                    "span",
                    {
                      staticClass: "notice-title",
                      on: {
                        click: function ($event) {
                          return _vm.showNotice(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.noticeTitle))]
                  ),
                  index === 0
                    ? _c("div", { staticClass: "notice-hot" }, [
                        _vm._v(_vm._s(_vm.$t("workbench.hot"))),
                      ])
                    : index === 1 || index === 2
                    ? _c("div", { staticClass: "notice-new" }, [
                        _vm._v(_vm._s(_vm.$t("workbench.new"))),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c("CompanyInfo", {
        ref: "companyInfo",
        attrs: { visible: _vm.infoVisible, user: _vm.user },
        on: {
          "update:visible": function ($event) {
            _vm.infoVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }