var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "grid-item",
      style: { zIndex: _vm.item.hover ? 10 : "auto" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleSelection($event)
        },
        mouseover: _vm.mouseoverItem,
        mouseleave: _vm.mouseleaveItem,
      },
    },
    [
      _c("div", { staticClass: "left-section" }, [
        _c("div", { staticClass: "video-grid-cover" }, [
          _c("video", {
            ref: "videoPlayer",
            staticClass: "video-grid-cover",
            attrs: {
              src: _vm.item.videoUrl,
              alt: "video",
              muted: "",
              playsinline: "",
              loop: "",
              preload: "auto",
            },
            domProps: { muted: true },
            on: { mouseenter: _vm.playVideo, mouseleave: _vm.pauseVideo },
          }),
          _c("div", { staticClass: "video-text-container flex-column" }, [
            _c(
              "div",
              {
                staticClass: "video-text",
                class: _vm.item.hover ? "" : "line-2",
              },
              [_vm._v(" " + _vm._s(_vm.item.postContent) + " ")]
            ),
            _c(
              "div",
              {
                staticClass:
                  "video-grid-labels flex-row-center single-line-ellipsis",
              },
              _vm._l(_vm.item.labels, function (tag, index) {
                return _c("span", { key: index, staticClass: "mr5" }, [
                  _vm._v(" #" + _vm._s(tag) + " "),
                ])
              }),
              0
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "video-grid-play",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.viewMedia(_vm.item)
                },
              },
            },
            [_c("i", { staticClass: "el-icon-caret-right" })]
          ),
        ]),
      ]),
      _c("div", { staticClass: "middle-section flex-row-center f-between" }, [
        _c("div", { staticClass: "flex-column-center" }, [
          _c("div", { staticClass: "num-num num-likes" }, [
            _vm._v(_vm._s(_vm.item.likes)),
          ]),
          _c("div", { staticClass: "num-name" }, [
            _vm._v(_vm._s(_vm.$t("faddish.likes"))),
          ]),
        ]),
        _c("div", { staticClass: "flex-column-center" }, [
          _c("div", { staticClass: "num-num num-shares" }, [
            _vm._v(_vm._s(_vm.item.shares)),
          ]),
          _c("div", { staticClass: "num-name" }, [
            _vm._v(_vm._s(_vm.$t("faddish.shares"))),
          ]),
        ]),
        _c("div", { staticClass: "flex-column-center" }, [
          _c("div", { staticClass: "num-num num-comments" }, [
            _vm._v(_vm._s(_vm.item.comments)),
          ]),
          _c("div", { staticClass: "num-name" }, [
            _vm._v(_vm._s(_vm.$t("faddish.comments"))),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "right-section flex-row-center f-between" }, [
        _c(
          "div",
          {
            class: _vm.item.hover ? "icon-hover" : "icon-normal",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("view-parse", _vm.item)
              },
            },
          },
          [
            _c("i", { staticClass: "iconfont icon-chaijie" }),
            _vm._v(" " + _vm._s(_vm.$t("faddish.copyAnalysis")) + " "),
          ]
        ),
        _c(
          "div",
          {
            class: _vm.item.hover ? "icon-hover" : "icon-normal",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("add-material", _vm.item)
              },
            },
          },
          [
            _c("i", { staticClass: "iconfont icon-tianjiazhisucaiku" }),
            _vm._v(" " + _vm._s(_vm.$t("faddish.addCopy")) + " "),
          ]
        ),
      ]),
      _vm.dialogVisible
        ? _c("VideoButtonGrid", {
            attrs: {
              dialogVisible: _vm.dialogVisible,
              url: _vm.mediaUrl,
              type: 0,
              tip: _vm.$t("file.filePreview"),
            },
            on: { closeMedia: _vm.closeMediaFun },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }