var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faddish-container" },
    [
      _c("div", { staticClass: "flex-row-center" }, [
        _c(
          "div",
          {
            staticClass: "f-title flex-row-center",
            staticStyle: { width: "25%", padding: "0 24px" },
          },
          [_vm._v(_vm._s(_vm.$t("faddish.breakdown")))]
        ),
        _c(
          "div",
          { staticClass: "flex-center", staticStyle: { width: "75%" } },
          [
            _c(
              "div",
              { staticClass: "flex-row-center", staticStyle: { width: "70%" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "f-grow mr5",
                    staticStyle: { padding: "0 24px" },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "account-input",
                        attrs: {
                          placeholder: _vm.$t("faddish.searchPlaceholder"),
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          },
                        },
                        model: {
                          value: _vm.queryParams.searchValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "searchValue", $$v)
                          },
                          expression: "queryParams.searchValue",
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "el-input__icon iconfont icon-sousuotubiao",
                          attrs: { slot: "prefix" },
                          on: { click: _vm.handleQuery },
                          slot: "prefix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "f-shrink",
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("refresh"),
                      placement: "top",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "iconfont icon-a-danyuanpeizhi6",
                      on: { click: _vm.refresh },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "faddish-content flex-row",
        },
        [
          _c(
            "div",
            { staticClass: "faddish-left" },
            [
              _vm._l(_vm.list, function (video, index) {
                return _c("list-item", {
                  key: index,
                  attrs: { video: video },
                  on: { "view-click": _vm.handleView },
                })
              }),
              _vm.list.length == 0
                ? _c("el-empty", { attrs: { "image-size": 200 } })
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "faddish-right flex-column-center" }, [
            _c(
              "div",
              { staticStyle: { width: "70%" } },
              [
                !_vm.currentItem
                  ? _c("div", { staticClass: "content-empty flex-center" }, [
                      _vm._v(" " + _vm._s(_vm.$t("faddish.clickToView")) + " "),
                    ])
                  : _c("detail", {
                      ref: "drawerDetail",
                      attrs: { item: _vm.currentItem },
                      on: { "make-video": _vm.makeVideo },
                    }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("mix-dialog", {
        ref: "mix",
        attrs: { visible: _vm.mixVisible },
        on: {
          "update:visible": function ($event) {
            _vm.mixVisible = $event
          },
          "make-video": _vm.makeVideo,
        },
      }),
      _c("add-text-dialog", {
        ref: "textDialog",
        attrs: {
          visible: _vm.textVisible,
          title: _vm.$t("copywriting.new"),
          "label-list": _vm.labelList,
          materialTypeList: _vm.materialTypeList,
        },
        on: {
          "update:visible": function ($event) {
            _vm.textVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }