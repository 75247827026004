<template>
  <div class="faddish-container">
    <div class="title-header">
      <div class="title-header-text">{{ $t('faddish.breakdown') }}</div>
      <div class="flex-row-center">
        <div style="width: 400px;margin:0 5px;">
          <el-input
            class="account-input"
            v-model="queryParams.searchValue"
            :placeholder="$t('faddish.searchPlaceholder')"
            @keyup.enter.native="handleQuery"
            clearable
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-sousuotubiao"
              @click="handleQuery"
            ></i>
          </el-input>
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <el-button
            size="mini"
            circle
            icon="el-icon-refresh"
            @click="refresh"
          />
        </el-tooltip>
      </div>
    </div>
    <div
      class="faddish-content"
      v-loading="loading"
    >
      <div class="flex-row f-wrap">
        <div
          class="video-parent flex-center"
          v-for="(video, index) in list"
          :key="index"
        >
          <grid-item
            :video="video"
            @view-click="handleView"
            @view-parse="handleParse"
            @add-material="handleAddMaterial"
          />
        </div>
      </div>
      <el-empty
        v-if="list.length==0"
        :image-size="200"
      ></el-empty>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        :pageSizes="[12,24,36,48,60]"
        @pagination="getList"
      />
    </div>
    <el-drawer
      :size="1000"
      class="publicDrawer"
      :visible.sync="drawerDetail"
      :with-header="true"
      :wrapperClosable="true"
      :show-close="false"
      :before-close="closeDetail"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('faddish.details') }}</div>
          <i
            class="el-icon-close"
            @click="closeDetail"
          ></i>
        </div>
      </div>
      <!-- :before-close="closePruning" -->
      <detail
        ref="drawerDetail"
        @make-video="detailMakeVideo"
      ></detail>
    </el-drawer>
    <mix-dialog
      ref="mix"
      :visible.sync="mixVisible"
      @make-video="makeVideo"
    />
    <add-text-dialog
      ref="textDialog"
      :visible.sync="textVisible"
      :title="$t('copywriting.new')"
      :label-list="labelList"
      :materialTypeList="materialTypeList"
    />
  </div>
</template>
<script>
import { listPopular } from "../../api/ai/faddish";
import GridItem from "./components/GridItem";
import Detail from "./detail";
import MixDialog from "./mixDialog";
import AddTextDialog from "../../components/dialog/addTextDialog.vue";
import { listLabel } from "../../api/system/label";
import { listMaterialType } from "../../api/ai/materialType";
export default {
  name: "Faddish",
  components: {
    GridItem,
    Detail,
    MixDialog,
    AddTextDialog,
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 24,
        searchValue: "",
      },
      loading: false,
      list: [],
      // 总条数
      total: 0,
      drawerDetail: false,
      mixVisible: false,
      textVisible: false, //文案弹窗
      labelList: [], //标签
      materialTypeList: [], //素材分类
    };
  },
  created() {
    this.getList();
    this.getLabels();
    this.getMaterialTypeList();
  },
  methods: {
    //爆款列表
    getList() {
      this.loading = true;
      listPopular(this.queryParams)
        .then((response) => {
          this.list = response.rows;
          this.total = response.total;
          this.list.forEach((item) => {
            item.hover = false;
            if (item.postContent) {
              item.postContent = item.postContent.split("#")[0].trim();
            }
            if (item.tags) {
              item.labels = JSON.parse(item.tags);
            } else {
              item.labels = [];
            }
            console.log(item.labels);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 标签列表
    getLabels() {
      listLabel().then((response) => {
        this.labelList = response.rows;
      });
    },
    //素材分类列表
    getMaterialTypeList() {
      listMaterialType().then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.materialTypeList = JSON.parse(
            JSON.stringify(response.data)
          ).filter((item) => item.type !== "system");
          this.materialTypeList.unshift({
            id: 0,
            name: this.$t("basicInfo.materia"),
            type: "system",
          });
        }
      });
    },
    // 查看详情
    handleView(row) {
      this.drawerDetail = true;
      this.$nextTick().then((rs) => {
        this.$refs.drawerDetail.init(row.id);
      });
    },
    // 关闭详情
    closeDetail() {
      this.drawerDetail = false;
      this.$nextTick().then((rs) => {
        this.$refs.drawerDetail.pauseVideo();
      });
    },
    // 文案解析
    handleParse(row) {
      this.mixVisible = true;
      this.$nextTick().then((rs) => {
        this.$refs.mix.init(row.id);
      });
    },
    // 添加到素材库
    handleAddMaterial(row) {
      let form = {
        title: row.postContent,
        content: row.videoContent,
        label: row.tags ? JSON.parse(row.tags).join(",") : "",
        labels: row.tags ? JSON.parse(row.tags) : [],
        materialTypeId: 0,
        fileType: "text",
      };
      this.$nextTick().then((rs) => {
        this.textVisible = true;
        this.$refs.textDialog.init(form);
      });
    },
    detailMakeVideo(detail) {
      this.drawerDetail = false;
      this.makeVideo(detail);
    },
    // 视频制作——跳转到文生视频第二步
    makeVideo(detail) {
      console.log("解析的文案", detail.videoContentList);
      sessionStorage.setItem(
        "editManuscriptsData",
        JSON.stringify([
          {
            title: detail.postContent,
            paragraphList: detail.videoContentList,
          },
        ])
      );
      this.$router.push({
        path: `/newCutting/${detail.id}`,
        query: {
          isFaddish: true,
        },
      });
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    refresh() {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.faddish-container {
  margin: 16px;
}
.faddish-content {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  margin-top: 16px;
  padding: 24px;
}
.custom-radio-group {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}
.custom-radio-group ::v-deep.el-radio {
  margin-right: 5px;
}
.custom-radio-group ::v-deep.el-radio.is-bordered.is-checked {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
.custom-radio-group ::v-deep.el-radio__input {
  display: none; /* 隐藏原点 */
}
.video-parent {
  width: calc(16% - 10px);
}
</style>
